import React, { useState, useEffect, useCallback } from "react";

// react package and library
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// material-ui style components 
import Step from "@mui/material/Step";
import { Pagination as CustomPagination, Radio, RadioGroup } from '@mui/material';
import Stepper from "@mui/material/Stepper";
import { StepLabel } from "@mui/material";
import Stripe from "images/stripe.png"
import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Slide,
    Stack,
    Switch,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    tableCellClasses
} from "@mui/material";
import { Table } from "react-bootstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import styled from "@emotion/styled";

// mui  and react icons
import Historylog from '../../../../images/historyicon.png'
import { RiFileCopy2Fill } from "react-icons/ri";
import {
    AddCircleOutline,
    Cancel,
    CheckCircle,
    Close,
    Delete,
    Download,
    Edit,
    LibraryAddCheck,
    Loop,
    Remove,
    Unsubscribe
} from "@mui/icons-material";
import loaderGif from "images/GIF-Loader-3.gif";
import { LoadingButton } from "@mui/lab";

// custom sccs file
import './licensesSummary.scss';

// custom components
import Footer from "components/footer/footer";
import Header from "components/header/header";
import Usersidebar from 'components/user-sidebar/user-sidebar';
import UserProfileAction from "redux/actions/UserProfielAction";
import { ContainerLoader } from "components/common/ContainerLoader";
import Pagination from "components/pagination/pagination";
import customDateFormatLocal from "utils/customDateFormatLocal";
import AlertMessage from "components/message/message";
import { decodeBase64ToParams, encodeParamsToBase64 } from "utils/base64";
import { useUserAPICollection } from "Hooks/useUserAPICollection";
import useAlertMessage from "Hooks/useAlertMessage";
import { changeDollarAmountformat } from "utils/changeDollarFormat";
import ChangePaymentMethod from "./ChangePaymentMethod";
import ConfirmationModal from "utils/confirmationModal";
import { getInternetPublicIpAddress } from "utils/getIpAddress";



const SwitchCustom = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

// custom styles
const btnStyle = {
    backgroundColor: "#EBEEF5 !important",
    color: "#002583!important",
    textTransform: "none!important",
    fontWeight: "bold",
    fontFamily: "Barlow!important",
    margin: "0 5px",
    borderRadius: "4px!important",
};

const purchaselicenseStyle = {
    backgroundColor: "#002180 !important",
    color: "#FFFFFF!important",
    textTransform: "none!important",
    fontWeight: "bold",
    fontFamily: "Barlow!important",
    margin: "0 5px",
    borderRadius: "4px!important",
};

const btnStyles = {
    backgroundColor: "#002583 !important",
    color: "white!important",
    textTransform: "none!important",
    fontWeight: "bold",
    fontFamily: "Barlow!important",
    margin: "0 5px",
    borderRadius: "4px!important",
};

const actionbutton = {
    backgroundColor: "#EBEEF5 !important",
    color: "#3A4E69!important",
    textTransform: "none!important",
    fontFamily: "Barlow!important",
    padding: "8px",
    border: "0.4px solid #EBEEF5",
};


const modalbtnStyle = {
    minWidth: "6rem!important",
    backgroundColor: "#001D7D!important",
    color: "white!important",
    fontFamily: "Barlow!important",
    textTransform: "none!important",
    fontSize: "1.1rem",
    fontWeight: "bold",
}

const purchasebtnmodalStyle = {
    minWidth: "8rem!important",
    backgroundColor: "#001D7D!important",
    color: "white!important",
    fontFamily: "Barlow!important",
    textTransform: "none!important",
    fontSize: "1.1rem",
    fontWeight: "bold",
};

const purchasebtnmodalStylePurchaeCredit = {
    maxWidth: "13rem!important",
    backgroundColor: "#001D7D!important",
    color: "white!important",
    fontFamily: "Barlow!important",
    textTransform: "none!important",
    fontSize: "1.1rem",
    width: "100%",
    marginTop: '11px',
    fontWeight: "bold",
};

const StyledTableCells = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary["dark"],
        color: theme.palette.common.white,
        fontFamily: "Barlow !important",
        fontWeight: "bold",
        textAlign: "left",
        position: "relative",
        // width: "100%",
        minWidth: "max-content",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        fontFamily: "Barlow !important",
        textAlign: "left",
        color: "#3C4E6A",
        borderBottom: "1px solid #CCCCCC",
        width: "auto",
        padding: "12px 15px",
    },
}));

const StyledTableRows = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    marginTop: "10px",
    color: theme.palette.text.secondary,
}));


const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
};

const steps = ["Product Details", " Choose Backup Payment Method", "Order Confirmation"];

const NewLicensesSummary = () => {
    const { getSripSavedCardsAPi,
        postReSubscribeLicenseApi,
        unsubscribeLicensesApi,
        addStripeCustPayMethodApi,
        getBrowserSingleLicense,
        getAvailableCredit,
        updateLicenseApi,
        getAllLicensesApi,
        deleteStripePaymentMethodApi
    } = useUserAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const dispatch = useDispatch();
    const limit = useSelector((state) => state.UserProfile?.data?.per_page_limit);
    const userProfie = useSelector((state) => state.UserProfile?.data);
    const [formData, setFormData] = useState({
        nickname: '',
    });
    const [licenseDataList, setLicenseDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditNickNameLoading, setEditNickNameLoading] = useState(false);
    const [isDisabledDownload, setIsDisabledDownload] = useState(false);
    const [isEditvisibleModel, setIsEditvisibleModel] = useState(false);
    const [licenseId, setLicenseId] = useState('');
    const [id, setId] = useState('');
    const [isCopy, setIsCopy] = useState(false);
    const [licenseKey, setLicenseKey] = useState('');
    const [logsModal, setLogsModal] = useState(false);
    const [licenselogs, setLicenseLogs] = useState([]);
    const [loadLogs, setLoadLogs] = useState(false);
    const [activeLicenses, setActiveLicenses] = useState(0);
    const [availableLicenses, setAvailableLicenses] = useState(0);
    const [expiredLicenses, setExpiredLicenses] = useState(0);
    const [totalLicenses, setTotalLicenses] = useState(0);
    const [isFilterStates, setIsFilterStates] = useState([]);
    // purchase Licenses   
    const [ispurchaseLoading, setIsPurchaseLoading] = useState(false);
    const [userAvailableCredit, setUserAvailableCredit] = useState(0);
    const [isVisibleModelStates, setIsVisibleModelStates] = useState({
        isVisiblePurchaseLicenseModal: false,
        isVisiblePaymentSuccessfull: false,
        isDisabledPurchaseLicenseButton: false,
        isVisiableCartSecondary: false,
        isVisibleResubscribeLicConfirmModal: false,
    });
    const [isPrimaryLicenseAdded, setIsPrimaryLicenseAdded] = useState(false);
    //license Log Pagination
    const [licenseLogPagination, setLicenseLogPagination] = useState({
        totalRecords: 0,
        searchPage: 0,
        skip: 0,
        pageLimit: limit
    });
    const [logId, setLogId] = useState();
    //unsubscribe Licenses
    const [isSecondPrimaryConfirmModal, setIsSecondPrimaryConfirmModal] = useState(false);
    const [isVisibleunsubscribeLicConfirmModal, setisVisibleunsubscribeLicConfirmModal] = useState(false)
    const [unsubscribeLicId, setUnsubscribeLicId] = useState()
    const [queryStringRemovedByUser, setQueryStringRemovedByUser] = useState(false);
    const statusOrder = {
        "Active": 1,
        "Inactive": 2,
        "Expired": 3,
        "Disabled": 4
    };
    const [nickname, setNickname] = useState("");
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const [searchPage, setSearchPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(limit);
    const totalRecords = licenseDataList?.length;
    const [stripeCardList, setStripeCardList] = useState([]);
    const [browserLicensePrice, setBrowserLicensePrice] = useState({
        primary: 0,
        secondary: 0,
        primary_price: 0,
    });
    const [cartItem, setCartItem] = useState({
        quantityPrimary: 1,
        totalAmountPaidPrimary: parseFloat(browserLicensePrice?.primary),
        quantitySecondary: 0,
        totalAmountPaidSecondary: 0
    });
    const [cartItemSecondary, setCartItemSecondary] = useState({
        quantity: 1,
        totalAmountPaid: parseFloat(browserLicensePrice?.secondary),
    });
    const [activeStep, setActiveStep] = React.useState(0);
    const [isValue, setIsValue] = useState(false);
    const [isAgreeAnonymousBrowserChecked, setIsAgreeAnonymousBrowserChecked] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [valiupData, setValiUpData] = useState({});
    const [isUpdateStateValue, setIsUpdateStateValue] = useState({
        isBack: false,
    });
    const [isEmailCopy, setIsEmailCopy] = useState(false);
    const [cardId, setCardId] = useState("");
    const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
    const [isbrowserChargeDate, setIsBrowserChargeDate] = useState(null);
    const [browerCharge, setBrowerCharge] = useState({
        browser_charge_date: null,
    });
    // change card info 
    const [isOpenChangeCardInfo, setIsOpenChangeCardInfo] = useState(false);
    const [selectedCardInfo, setSelectedCardInfo] = useState(null);
    const [userId, setUserId] = useState(null);

    const handleOpenChangeCardInfo = (id, _id) => {
        setSelectedCardInfo(id)
        setUserId(_id)
        setIsOpenChangeCardInfo(true);
    };

    const hnadleCloseChangeCardInfo = () => {
        setIsOpenChangeCardInfo(false)
        setSelectedCardInfo(null)
    }

    const handleOnChangeCardInfo = (id) => {
        setSelectedCardInfo(id)
    }

    const handleIncrementQuantity = () => {
        setCartItem(prevCartItem => ({
            ...prevCartItem,
            quantityPrimary: prevCartItem.quantityPrimary + 1,
            totalAmountPaidPrimary: prevCartItem.totalAmountPaidPrimary + parseFloat(browserLicensePrice?.primary)
        }));
    };

    const handleDecrementQuantity = () => {
        if (cartItem.quantityPrimary > 1) {
            setCartItem(prevCartItem => ({
                ...prevCartItem,
                quantityPrimary: prevCartItem.quantityPrimary - 1,
                totalAmountPaidPrimary: prevCartItem.totalAmountPaidPrimary - parseFloat(browserLicensePrice?.primary)
            }));
        }
    };

    const handleIncrementSecondary = () => {
        setCartItem(prevCartItem => ({
            ...prevCartItem,
            quantitySecondary: prevCartItem.quantitySecondary + 1,
            totalAmountPaidSecondary: prevCartItem.totalAmountPaidSecondary + parseFloat(browserLicensePrice?.secondary)
        }));
    };

    const handleDecrementSecondary = () => {
        if (cartItem.quantitySecondary > 0) {
            setCartItem(prevCartItem => ({
                ...prevCartItem,
                quantitySecondary: prevCartItem.quantitySecondary - 1,
                totalAmountPaidSecondary: prevCartItem.totalAmountPaidSecondary - parseFloat(browserLicensePrice?.secondary)
            }));
        } else {
            setCartItem(prevCartItem => ({
                ...prevCartItem,
                quantitySecondary: 0,
                totalAmountPaidSecondary: 0
            }));
        }
    };

    const handleIncrementQuantitySecondary = () => {
        setCartItemSecondary((prevCartItem) => ({
            ...prevCartItem,
            quantity: prevCartItem.quantity + 1,
            totalAmountPaid: prevCartItem.totalAmountPaid + parseFloat(browserLicensePrice?.secondary),
        }));
    };

    const handleDecrementQuantitySecondary = () => {
        if (cartItemSecondary.quantity > 1) {
            setCartItemSecondary((prevCartItem) => ({
                ...prevCartItem,
                quantity: prevCartItem.quantity - 1,
                totalAmountPaid: prevCartItem.totalAmountPaid - parseFloat(browserLicensePrice?.secondary),
            }));
        } else {
            setCartItemSecondary({
                ...cartItemSecondary,
                quantity: 1,
                totalAmountPaid: parseFloat(browserLicensePrice?.secondary),
            })
        }
    };

    const handlePurchaseLicense = async () => {
        if (selectedCard) {
            try {
                setIsVisibleModelStates({
                    ...isVisibleModelStates,
                    isDisabledPurchaseLicenseButton: true,
                });
                const api_params = {
                    charge_amt: !isPrimaryLicenseAdded === true ? (cartItem?.totalAmountPaidPrimary + cartItem?.totalAmountPaidSecondary) : cartItemSecondary.totalAmountPaid,
                    quantity: !isPrimaryLicenseAdded === true ? (cartItem?.quantityPrimary + cartItem?.quantitySecondary) : cartItemSecondary.quantity,
                    payment_method_id: selectedCard,
                    subscription_type: "recurring",
                }
                const purchase_license_api_url = process.env.REACT_APP_API_URL + "/api/v2/browser/purchase";
                const response = await axios.post(purchase_license_api_url, api_params, {
                    headers: {
                        authorization: "Bearer " + Cookies.get("accessToken")
                    },
                });
                const { data } = response;
                if (data.error) {
                    history.replace(window.location.pathname)
                    errorMessage()
                    setIsVisibleModelStates({
                        ...isVisibleModelStates,
                        isVisiblePurchaseLicenseModal: false,
                    });
                    setActiveStep(0)
                    setSelectedCard(null)
                    handleResetPurchaseLicenseState()
                } else {
                    history.replace(window.location.pathname)
                    setIsVisibleModelStates({
                        ...isVisibleModelStates,
                        isVisiblePurchaseLicenseModal: false,
                        isVisiblePaymentSuccessfull: true
                    });
                    await getAllLicenses(isFilterStates)
                    setActiveStep(0)
                    setSelectedCard(null)
                    handleResetPurchaseLicenseState()
                }
            } catch (error) {
                history.replace(window.location.pathname)
                errorMessage()
                setIsVisibleModelStates({
                    ...isVisibleModelStates,
                    isVisiblePurchaseLicenseModal: false
                });
                setActiveStep(0)
                setSelectedCard(null)
                handleResetPurchaseLicenseState()
            }
        } else {
            errorMessage("Please Select Payment Method! ", "error")
        }
    }

    const handleAddStripeCustomPayMethod = async () => {
        const url = window.location.href;
        const params = {
            totalAmountPaidPrimary: !isPrimaryLicenseAdded === true ? cartItem?.totalAmountPaidPrimary : cartItemSecondary.totalAmountPaid,
            totalAmountPaidSecondary: !isPrimaryLicenseAdded === true ? cartItem?.totalAmountPaidSecondary : cartItemSecondary.totalAmountPaid,
            quantityPrimary: !isPrimaryLicenseAdded === true ? cartItem?.quantityPrimary : cartItemSecondary.quantity,
            quantitySecondary: !isPrimaryLicenseAdded === true ? cartItem?.quantitySecondary : cartItemSecondary.quantity,
            activeStep: activeStep
        };
        const encodedParams = encodeParamsToBase64(params);
        if (encodedParams) {
            try {
                setIsVisibleModelStates({
                    ...isVisibleModelStates,
                    isDisabledPurchaseLicenseButton: true
                })
                const response = await addStripeCustPayMethodApi({ success_url: `${url}?p=${encodedParams}` })
                const { data } = response;
                if (data.error) {
                    errorMessage()
                    setIsVisibleModelStates({
                        ...isVisibleModelStates,
                        isDisabledPurchaseLicenseButton: false
                    })
                } else {
                    window.location.href = data?.checkout_url;
                    setIsVisibleModelStates({
                        ...isVisibleModelStates,
                        isDisabledPurchaseLicenseButton: false
                    })
                }
            } catch (error) {
                errorMessage()
                setIsVisibleModelStates({
                    ...isVisibleModelStates,
                    isDisabledPurchaseLicenseButton: false
                })
            }
        } else {
            errorMessage('Something went wrong, Please try again ', "error")
            setIsVisibleModelStates({
                ...isVisibleModelStates,
                isDisabledPurchaseLicenseButton: false
            })
        }
    }

    const handleStripeCustomPayChangeCardInfo = async () => {
        const url = window.location.href;
        const params = {
            isOpenChangeCardInfo: true,
        };
        const encodedParams = encodeParamsToBase64(params);
        if (encodedParams) {
            try {
                const response = await addStripeCustPayMethodApi({ success_url: `${url}?m=${encodedParams}` })
                const { data } = response;
                if (data.error) {
                    errorMessage()
                } else {
                    window.location.href = data?.checkout_url;
                }
            } catch (error) {
                errorMessage()
            }
        } else {
            errorMessage('Something went wrong, Please try again ', "error")
        }
    }

    const getLicenceLogs = async (pageskip = 0, limitpage = 10, id = logId) => {
        setPageLimit(limitpage)
        setLicenseLogPagination({
            ...licenseLogPagination,
            searchPage: pageskip
        })
        setLoadLogs(true);
        try {
            const get_all_licenses_api_url = process.env.REACT_APP_API_URL + "/api/v2/browser/getLicenseLogs";
            const response = await axios.post(get_all_licenses_api_url, {
                _id: id,
                skip: pageskip,
                limit: limitpage
            }, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken")
                },
            }
            );
            const { data } = response;
            if (data.error) {
                errorMessage()
                setLoadLogs(false);
            } else {
                setLicenseLogs(data?.data);
                setLoadLogs(false);
                setLicenseLogPagination({
                    ...licenseLogPagination,
                    totalRecords: data?.total ?? 10
                })
            }
        } catch (error) {
            setLoadLogs(false);
            errorMessage()
        }
    }


    const getSavedStripeList = async () => {
        try {
            setIsPurchaseLoading(true)
            const response = await getSripSavedCardsAPi();
            const { data } = response;
            if (data.error) {
                errorMessage()
                setIsPurchaseLoading(false)
            } else {
                setStripeCardList(data?.payment_methods)
                setIsPurchaseLoading(false)
            }
        } catch (error) {
            setIsPurchaseLoading(false)
            errorMessage()
        }
    };

    const getAllLicenses = async (status = []) => {
        try {
            setIsLoading(true)
            const get_all_licenses_api_url = process.env.REACT_APP_API_URL + "/api/v2/browser/getAllLicenses";
            const response = await axios.post(get_all_licenses_api_url, {
                nickname: nickname,
                status: status,
            }, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken")
                },
            });
            const { data } = response;
            if (data.tokenExpired && data.error) {
                errorMessage(data?.message, "error");
                Cookies.remove('at')
                Cookies.remove('accessToken')
                history.push("/login")
            } else if (data.error) {
                setIsLoading(false);
                setLicenseDataList([])
            } else {
                const sortData = data?.data.sort((a, b) => {
                    if (a.license_type === 'primary' && b.license_type !== 'primary') {
                        return -1; // Move 'primary' to the top
                    } else if (a.license_type !== 'primary' && b.license_type === 'primary') {
                        return 1; // Move 'primary' to the top
                    } else if ((a.license_type === 'primary' && b.license_type === 'primary') &&
                        (a?.license_data?.status !== 'Expired' && b?.license_data?.status === 'Expired')) {
                        return -1; // Move 'primary' with non-'Expired' status to the top
                    } else if ((a.license_type === 'primary' && b.license_type === 'primary') &&
                        (a?.license_data?.status === 'Expired' && b?.license_data?.status !== 'Expired')) {
                        return 1; // Move 'primary' with 'Expired' status to the bottom
                    } else {
                        return 0; // Maintain the relative order
                    }
                });
                const datas = sortData?.sort((a, b) => {
                    if (a.license_data.status === b.license_data.status) {
                        return 0;
                    }
                    return statusOrder[a?.license_data?.status] - statusOrder[b?.license_data?.status];
                })
                const newArraylicData = datas.map(lic => {
                    const card = stripeCardList.find(card => card.id === lic.payment_method_id);
                    if (card) {
                        return { ...lic, cardInfo: card }; // Add card info to a new object
                    }
                    return lic; // Return the original object if no card info is found
                });
                setLicenseDataList(newArraylicData);
                setIsPrimaryLicenseAdded(data?.isPrimaryAdded ?? false)
                setAvailableLicenses(data?.available ?? 0)
                setTotalLicenses(data?.count ?? 0)
                setActiveLicenses(data?.active ?? 0)
                setExpiredLicenses(data?.expired ?? 0)
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage()
            setLicenseDataList([])
            setIsLoading(false);
        }
    };

    const getBrowserLicensePrices = async () => {
        try {
            const get_browser_licenses_prices = process.env.REACT_APP_API_URL + "/api/v2/browser/getLicensePrices";
            const response = await axios.get(get_browser_licenses_prices, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken")
                },
            });
            const { data } = response;
            if (data?.error) {
                errorMessage()
            } else {
                const current_date = new Date();
                const browser_charge_date = new Date(data?.data?.browser_charge_date);
                setIsBrowserChargeDate(current_date < browser_charge_date)
                setBrowerCharge({
                    ...browerCharge,
                    browser_charge_date: browser_charge_date ?? null,
                });
                const browserChargeAmount = (100 - parseFloat(data?.data?.browser_charge_discount)) / 100;
                const finalAmout = parseFloat(browserChargeAmount) * parseFloat(data?.data?.primary);
                setBrowserLicensePrice({
                    ...browserLicensePrice,
                    primary: current_date < browser_charge_date ? parseFloat(finalAmout) : data?.data && data?.data?.primary,
                    secondary: data?.data && data?.data?.secondary,
                    primary_price: data?.data && data?.data?.primary,
                })
            }
        } catch (error) {
            errorMessage()
        }
    }

    const handleDownloadBrowser = async (id) => {
        const publicIPAddress = localStorage.getItem('publicIPAddress');
        if (publicIPAddress) {
            try {
                setIsDisabledDownload(true)
                setId(id)
                const download_lincense_key_browser_api = process.env.REACT_APP_API_URL + "/api/v2/browser/downloadBrowser";
                const response = await axios.post(download_lincense_key_browser_api,
                    {
                        _id: id,
                        ip: publicIPAddress
                    },
                    {
                        headers: {
                            authorization: "Bearer " + Cookies.get("accessToken")
                        },
                    });
                const { data } = response;
                if (data.error) {
                    errorMessage()
                    setIsDisabledDownload(false)
                } else {
                    const urlbrowser = "https://ab-dashboard-installer.s3.amazonaws.com/" + data?.data?.browserlatestfile;
                    const link = document.createElement('a');
                    link.href = urlbrowser;
                    link.download = data?.data?.browserlatestfile;
                    link.href = urlbrowser;
                    link.click();
                    setId('')
                    setIsDisabledDownload(false)
                }
            } catch (error) {
                errorMessage()
                setIsDisabledDownload(false)
            }
        } else {
            errorMessage("Something went wrong, Please try again", "error")
            getInternetPublicIpAddress()
        }
    };

    const getSingleLicenseApi = async (id) => {
        setLicenseId(id)
        try {
            setIsEditvisibleModel(true)
            setEditNickNameLoading(true)
            const response = await getBrowserSingleLicense({ _id: id });
            const { data } = response;
            if (data.error) {
                errorMessage()
                setIsEditvisibleModel(false)
                setEditNickNameLoading(false)
            } else {
                setFormData({
                    ...formData,
                    nickname: data?.data?.nickname ?? ''
                });
                setEditNickNameLoading(false)
            }
        } catch (error) {
            errorMessage()
            setIsEditvisibleModel(false)
            setEditNickNameLoading(false)
        }
    }

    const handleUpdateLicense = async () => {
        setIsValue(true)
        try {
            const parmas = {
                _id: licenseId,
                nickname: formData?.nickname
            }
            const paymentchangeInfo = {
                _id: userId,
                payment_method_id: selectedCardInfo
            }
            const response = await updateLicenseApi(selectedCardInfo ? paymentchangeInfo : parmas);
            const { data } = response;
            if (data.error) {
                errorMessage(data.message, "error")
                setIsOpenChangeCardInfo(false)
                history.replace(window.location.pathname)
                setQueryStringRemovedByUser(true);
            } else {
                if (data.message === "Payment Id updated successfully!") {
                    history.replace(window.location.pathname)
                    errorMessage(data.message, "success")
                    setIsOpenChangeCardInfo(false)
                    setQueryStringRemovedByUser(true);
                    await getAllLicenses(isFilterStates)
                } else {
                    const response = await getAllLicensesApi({
                        nickname: nickname,
                        status: isFilterStates,
                    });
                    const updatedLicenseDataList = response?.data?.data?.map((item) => {
                        return { ...item };
                    });
                    const indexOfLicenseId = updatedLicenseDataList.findIndex(item => (item && item?.id) === licenseId);
                    if (indexOfLicenseId !== -1 && indexOfLicenseId !== updatedLicenseDataList.length - 1) {
                        const updatedList = [
                            ...updatedLicenseDataList.slice(0, indexOfLicenseId),
                            ...updatedLicenseDataList.slice(indexOfLicenseId + 1),
                            updatedLicenseDataList[indexOfLicenseId]
                        ];
                        setLicenseDataList(updatedList);
                    } else {
                        setLicenseDataList([...updatedLicenseDataList]);
                    }
                    errorMessage(data.message, "success")
                    setIsEditvisibleModel(false);
                    setIsOpenChangeCardInfo(false)
                }
            }
        } catch (error) {
            errorMessage()
            setIsOpenChangeCardInfo(false)
        }
    };

    const updateProfile = async (perpageLimit) => {
        try {
            const updateDetail_api_url = process.env.REACT_APP_API_URL + "/api/user/updateUser";
            const response = await axios.post(
                updateDetail_api_url,
                { per_page_limit: perpageLimit },
                {
                    headers: {
                        authorization: "Bearer " + Cookies.get("accessToken"),
                    },
                }
            );
            const { data } = response;
            if (data.error) {
                errorMessage()
            } else {
                dispatch(UserProfileAction());
            }
        } catch (error) {
            errorMessage()
        }
    };

    const getUserAvailableCredit = async () => {
        try {
            setIsPurchaseLoading(true)
            const response = await getAvailableCredit();
            const { data } = response;
            if (data.error) {
                errorMessage()
                setIsPurchaseLoading(false)
            } else {
                setUserAvailableCredit(parseFloat(data?.data ?? 0.00).toFixed(2))
                setIsPurchaseLoading(false)
            }
        } catch (error) {
            errorMessage()
            setIsPurchaseLoading(false)
        }
    };

    const handleUnsubscribeLicenses = async () => {
        try {
            const response = await unsubscribeLicensesApi({ _id: unsubscribeLicId })
            const { data } = response;
            if (data.error) {
                setisVisibleunsubscribeLicConfirmModal(false)
                setIsSecondPrimaryConfirmModal(false);
                errorMessage()
            } else {
                setisVisibleunsubscribeLicConfirmModal(false)
                setIsSecondPrimaryConfirmModal(false);
                errorMessage(data.message, "success")
                await getAllLicenses(isFilterStates)
            }
        } catch (error) {
            setisVisibleunsubscribeLicConfirmModal(false)
            setIsSecondPrimaryConfirmModal(false);
            errorMessage()
        }
    };

    const handleReSubscribeLicense = async () => {
        handleCloseUnsubscribeLicnsesModal()
        try {
            const response = await postReSubscribeLicenseApi({ _id: unsubscribeLicId })
            const { data } = response;
            if (data.error) {
                errorMessage(!data?.message ? 'Something went wrong' : data?.message, "error")
            } else {
                errorMessage(data?.message, "success")
                await getAllLicenses(isFilterStates)
            }
        } catch (error) {
            errorMessage()
        }
    };

    const handleDeleteStripePaymentMethod = async () => {
        try {
            setIsDeleteModalShow(false)
            const response = await deleteStripePaymentMethodApi({ payment_method_id: cardId })
            const { data } = response;
            if (data.error) {
                errorMessage()
            } else {
                errorMessage(data.message, "success")
                await getSavedStripeList()
            }
        } catch (error) {
            errorMessage()
        }
    }

    const handleVisableDeleteConfimationModal = (id) => {
        setIsDeleteModalShow(true)
        setCardId(id)
    }

    const handleCloseDeleteModal = (id) => {
        setIsDeleteModalShow(false)
        setCardId("")
    }

    const handleClosePaymentSuccessfull = () => {
        localStorage.removeItem('licensePageUrl');
        setQueryStringRemovedByUser(true);
        setIsVisibleModelStates({
            ...isVisibleModelStates,
            isVisiblePurchaseLicenseModal: false,
            isVisiblePaymentSuccessfull: false,
        });
        setActiveStep(0)
        setSelectedCard(null)
        handleResetPurchaseLicenseState()
    }

    const handleUpdateNickNameOnBlur = () => {
        handleUpdateLicense()
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleUpdateLicense();
        }
    }

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const changePageLimitLicenseLog = (e) => {
        setLicenseLogPagination({
            ...licenseLogPagination,
            searchPage: 1,
        })
        updateProfile(e.target.value);
        getLicenceLogs(0, parseInt(e.target.value), logId);

    };

    const onPageChangedLicenseLog = (data) => {
        const { currentPage } = data;
        setLicenseLogPagination({
            ...licenseLogPagination,
            skip: (currentPage - 1) * licenseLogPagination?.pageLimit
        })
        getLicenceLogs((currentPage - 1) * pageLimit, pageLimit, logId);
    };

    const SearchDisableLicenseLog = (value) => {
        setLicenseLogPagination({
            ...licenseLogPagination,
            searchPage: value,
        })
    };

    const viewLogs = (id) => {
        setLogId(id)
        setLogsModal(true);
        getLicenceLogs(0, pageLimit, id)
    }

    const hideLogsModal = () => {
        setSelectedCard(null)
        setActiveStep(0)
        setLogsModal(false);
        setIsVisibleModelStates({
            ...isVisibleModelStates,
            isVisiblePurchaseLicenseModal: false,
            isVisiableCartSecondary: false
        });
        history.replace(window.location.pathname)
        handleResetPurchaseLicenseState()
        setIsAgreeAnonymousBrowserChecked(false)
        setIsAgreeAnonymousBrowserChecked(false)
    }

    const handleVisibleUnsubscribeLicnsesModal = (e, id, item) => {
        setisVisibleunsubscribeLicConfirmModal(true)
        setUnsubscribeLicId(id)
        setValiUpData(item)
    }

    const handleVisibleReSubscribeLicnsesModal = (e, item) => {
        if (item) {
            setIsVisibleModelStates({
                ...isVisibleModelStates,
                isVisibleResubscribeLicConfirmModal: true
            });
            setUnsubscribeLicId(item?._id);
            setValiUpData(item);
        } else {
            errorMessage("License has expired!", "error")
        }
    }

    const handleOpenSecondPrimaryLicense = () => {
        setIsSecondPrimaryConfirmModal(true);
    }

    const handleCloseSecondPrimaryLicense = () => {
        setIsSecondPrimaryConfirmModal(false);
    }

    const handleCloseUnsubscribeLicnsesModal = () => {
        setisVisibleunsubscribeLicConfirmModal(false)
        setIsVisibleModelStates({
            ...isVisibleModelStates,
            isVisibleResubscribeLicConfirmModal: false
        });
    }

    const handleInputChange = (event) => {
        const value = event.target.value;
        setFormData({
            ...formData,
            nickname: value
        });
    };

    const handleCapitalize = useCallback((str) => {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    }, []);

    const handleCopyToClipboard = (content) => {
        setLicenseKey(content);
        const el = document.createElement('textarea');
        setIsCopy(true)
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const handleCopyToClipboardEmail = (content) => {
        const el = document.createElement('textarea');
        setIsEmailCopy(true)
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    useEffect(() => {
        let interval;
        if (isCopy || isEmailCopy) {
            interval = setInterval(() => {
                setIsCopy(false);
                setIsEmailCopy(false);
            }, 2000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isCopy, isEmailCopy]);


    const handleSubmitFilter = async (state) => {
        let newArray = [];
        if (state) {
            if (state === "all") {
                setIsFilterStates([]);
            } else {
                const updatedArray = isFilterStates.includes(state)
                    ? isFilterStates.filter(item => item !== state)
                    : [...isFilterStates, state];
                setIsFilterStates(updatedArray);
                newArray = updatedArray;
            }
            await getAllLicenses(newArray)
        }
    };

    const calculateDays = useCallback((endDate, startDate) => {
        if (!endDate || !startDate) return null;
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
        const daysDifference = Math.floor(differenceInMilliseconds / millisecondsPerDay);
        return daysDifference + 1;
    }, []);

    const handleOnChanges = (e) => {
        const value = e.target.value;
        if (value) {
            setNickname(e.target.value);
        } else {
            setNickname('')
        }
    }

    const handleSerachKeyPress = (event) => {
        if (event.key === 'Enter') {
            getAllLicenses(isFilterStates)
        }
    }

    const onPageChanged = (event, pageNumber) => {
        setSearchPage(pageNumber);
    };

    const changePageLimit = (e) => {
        const newPageLimit = parseInt(e.target.value, 10);
        updateProfile(e.target.value);
        setPageLimit(newPageLimit);
        setSearchPage(1);
    };

    const handleVisiblePurchaseLicenseModal = () => {
        setIsVisibleModelStates({
            ...isVisibleModelStates,
            isVisiblePurchaseLicenseModal: true,
        });
        handleResetPurchaseLicenseState()
    }

    const handleResetPurchaseLicenseState = () => {
        setCartItem({
            ...cartItem,
            quantityPrimary: 1,
            totalAmountPaidPrimary: parseFloat(browserLicensePrice?.primary),
            quantitySecondary: 0,
            totalAmountPaidSecondary: 0
        });
        setCartItemSecondary({
            ...cartItemSecondary,
            quantity: 1,
            totalAmountPaid: parseFloat(browserLicensePrice?.secondary),
        })
    }

    const totalSteps = () => {
        return steps.length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep()
                ? steps.findIndex((step, i) => !(i))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        if (isUpdateStateValue.isBack === false) {
            handleSetStatePurchaseLicense()
        }
        setIsUpdateStateValue({
            ...isUpdateStateValue,
            isBack: true
        })
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (isUpdateStateValue.isBack === false) {
            handleSetStatePurchaseLicense()
        }
        setIsUpdateStateValue({
            ...isUpdateStateValue,
            isBack: true
        })
        setIsAgreeAnonymousBrowserChecked(false);
    };

    const handleCardClick = (id) => {
        setSelectedCard(id);
    };

    const handleAgreeToAnonymousBrowserChecked = (e) => {
        setIsAgreeAnonymousBrowserChecked(e.target.checked)
    }

    const handleSetStatePurchaseLicense = () => {
        const encodedParams = urlParams?.get("p");
        if (encodedParams) {
            const decodedParams = decodeBase64ToParams(encodedParams);
            if (decodedParams) {
                if (!isPrimaryLicenseAdded === true) {
                    setCartItem({
                        ...cartItem,
                        quantityPrimary: parseInt(decodedParams?.quantityPrimary),
                        totalAmountPaidPrimary: parseFloat(decodedParams?.totalAmountPaidPrimary),
                        quantitySecondary: parseInt(decodedParams?.quantitySecondary),
                        totalAmountPaidSecondary: parseFloat(decodedParams?.totalAmountPaidSecondary)
                    });
                } else {
                    setCartItemSecondary({
                        ...cartItemSecondary,
                        quantity: parseInt(decodedParams?.quantity),
                        totalAmountPaid: parseFloat(decodedParams?.totalAmountPaidSecondary)
                    });
                }
            }
        }
    };

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (action === 'POP' && !queryStringRemovedByUser) {
                history.replace(location.pathname);
            }
            setQueryStringRemovedByUser(false);
        });
        return () => {
            unlisten();
        };
    }, [history, queryStringRemovedByUser]);

    useEffect(() => {
        const encodedParams = urlParams.get("p")
        if (encodedParams) {
            const decodedParams = decodeBase64ToParams(encodedParams);
            if (decodedParams) {
                handleSetStatePurchaseLicense()
                setIsVisibleModelStates({
                    ...isVisibleModelStates,
                    isVisiblePurchaseLicenseModal: true,
                });
                setActiveStep((decodedParams && decodedParams?.activeStep))
            }
        }
        const encodedParamsCardInfo = urlParams.get("m")
        if (encodedParamsCardInfo) {
            const decodedParamsCardInfo = decodeBase64ToParams(encodedParamsCardInfo);
            if (decodedParamsCardInfo) {
                console.log(decodedParamsCardInfo?.isOpenChangeCardInfo);
                setIsOpenChangeCardInfo(true)
            }
        }
    }, []);

    useEffect(() => {
        getSavedStripeList();
        getAllLicenses(isFilterStates)
        getBrowserLicensePrices();
        getUserAvailableCredit()
        getInternetPublicIpAddress()
    }, []);

    const startIndex = (searchPage - 1) * pageLimit;
    const endIndex = startIndex + pageLimit;
    const currentData = licenseDataList?.slice(startIndex, endIndex);
    const renewalAmount = valiupData?.license_type === "secondary" ? browserLicensePrice?.secondary : browserLicensePrice?.primary_price;
    const message = `The next auto-renewal of this license in the amount of $${renewalAmount}/month will be 
    ${!valiupData?.validUpto ? "30 days after activation" : "on " + new Intl.DateTimeFormat('en', options).format(new Date(valiupData?.validUpto ?? null))}. Are you sure you want to continue?`

    return <>
        <div className="user-dashboard-wrapper">
            <Helmet>
                <title>Licenses Summary</title>
            </Helmet>
            <Header />
            <div className="c-app c-default-layout user-dashboard-body">
                <Usersidebar />
                <div className="c-wrapper">
                    <div className="c-body">
                        <main className="c-main">
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="space-top-col">
                                    <Card
                                        className="card-licenses-summary"
                                        sx={{
                                            width: "100%",
                                            maxWidth: "100%",
                                            color: "#000059!important",
                                            textAlign: "left",
                                            padding: "30px",
                                            backgroundColor: "#fbfbfb;",
                                        }}
                                    >
                                        <div className="licensesummary-header">
                                            <Grid
                                                container
                                                spacing={2}
                                                className="licensesummary-header-grid"
                                            >
                                                <Grid item>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            width: "100%",
                                                            maxWidth: "313px",
                                                            color: "#000059!important",
                                                            textAlign: "left",
                                                            fontWeight: "700",
                                                            fontSize: "22px",
                                                            lineHeight: '28.8px',
                                                            fontFamily: "Barlow!important",
                                                            marginTop: '-11px',
                                                            marginLeft: '5px',
                                                        }}
                                                    >
                                                        Licenses Summary
                                                        <br />
                                                        <span style={{ fontSize: "15px" }}>
                                                            <section>
                                                                Email address:{" "}{userProfie && userProfie?.email}
                                                                {isEmailCopy ? (
                                                                    <LibraryAddCheck className="tooltip-container-checkCopy-icon" />
                                                                ) : (
                                                                    <Tooltip
                                                                        onClick={() => handleCopyToClipboardEmail(userProfie?.email)}
                                                                        title="Copy"
                                                                        className="tooltip-container"
                                                                    >
                                                                        <RiFileCopy2Fill />
                                                                    </Tooltip>
                                                                )}
                                                            </section>
                                                        </span>
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                width: "100%",
                                                                maxWidth: "565px",
                                                                color: "#000059!important",
                                                                textAlign: "left",
                                                                fontWeight: "600",
                                                                fontSize: "22px",
                                                                marginLeft: '1px',
                                                                lineHeight: '26.4px',
                                                                fontFamily: "Barlow!important",
                                                                marginTop: '27px'
                                                            }}
                                                        >
                                                            {" "}
                                                            Product :  Anonymous Browser
                                                        </Typography>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                spacing={{ xs: 1, md: 1, lg: 1 }}
                                                columns={{ xs: 4, sm: 8, md: 8 }}
                                                className="button-align-lic"
                                            >
                                                <Grid item>
                                                    <Card className="card-custom-lic">
                                                        <CardContent className="licnses-cardContent">
                                                            <div className="account-have-img-license">
                                                                <Grid container spacing={0}>
                                                                    <Grid
                                                                        item
                                                                        xs="12"
                                                                        sm="8"
                                                                        md="8"
                                                                        lg="8"
                                                                        sx={{
                                                                            padding: "0px 22px!important",
                                                                            display: "flex",
                                                                            alignItems: "flex-end",
                                                                            justifyContent: "flex-start",
                                                                        }}
                                                                    >
                                                                        <div className="plan-prices-lic card-textAlign">
                                                                            <span>{parseInt(activeLicenses + availableLicenses) ?? "0"}</span>
                                                                            <h2
                                                                                style={{
                                                                                    width: '100%',
                                                                                    minWidth: "max-content",
                                                                                    color: '#000057 !important'
                                                                                }}
                                                                            >
                                                                                Total Licenses
                                                                            </h2>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="align-buttons-lic"
                                            >
                                                <div className="filter-button-group">
                                                    <div>
                                                        <ButtonGroup
                                                            variant="contained"
                                                            className="filter-list-button-div"
                                                            sx={{
                                                                boxShadow: "none",
                                                                "& button": {
                                                                    marginTop: {
                                                                        xs: "20px",
                                                                        sm: "20px",
                                                                        md: "20px",
                                                                        height: "max-content",
                                                                    },
                                                                },
                                                                "& a": {
                                                                    marginTop: {
                                                                        xs: "20px",
                                                                        sm: "20px",
                                                                        md: "20px"
                                                                    },
                                                                    maxHeight: "34px"
                                                                },
                                                                flexWrap: {
                                                                    xs: "wrap",
                                                                    sm: "wrap",
                                                                    md: "wrap",
                                                                    lg: "nowrap",
                                                                }
                                                            }}
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={{ xs: 1, md: 1, lg: 1 }}
                                                                columns={{ xs: 4, sm: 8, md: 8 }}
                                                            >
                                                                <Grid item>
                                                                    <Button
                                                                        disabled={!isFilterStates.length > 0 ? true : false || totalLicenses === 0 ? true : false}
                                                                        onClick={() => handleSubmitFilter("all")}
                                                                        sx={!isFilterStates.length > 0 ? btnStyles : btnStyle}
                                                                        variant="contained"
                                                                        className={!isFilterStates.length > 0 ? "filter-list-button-active" : "filter-list-button"}
                                                                        endIcon={
                                                                            <span className={!isFilterStates.length > 0 ? "button-icon-active" : "button-icon"}>
                                                                                <Typography
                                                                                    variant="h5"
                                                                                    className={!isFilterStates.length > 0 ? "filter-button-text-active " : "filter-button-text"}
                                                                                >
                                                                                    {totalLicenses ?? 0}
                                                                                </Typography>
                                                                            </span>
                                                                        }>
                                                                        All
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        disabled={isLoading || activeLicenses === 0 ? true : false}
                                                                        onClick={() => handleSubmitFilter('active')}
                                                                        sx={isFilterStates.includes("active") ? btnStyles : btnStyle}
                                                                        variant="contained"
                                                                        className={isFilterStates.includes("active") ? "filter-list-button-active btn-active-color" : "filter-list-button button-color-active"}
                                                                        endIcon={
                                                                            <span className={isFilterStates.includes("active") ? "button-icon-active" : "button-icon"}>
                                                                                <Typography
                                                                                    variant="h5"
                                                                                    className={isFilterStates.includes("active") ? "filter-button-text-active " : "filter-button-text active-icon"}
                                                                                >
                                                                                    {activeLicenses ?? 0}
                                                                                </Typography>
                                                                            </span>
                                                                        }>
                                                                        Active
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        disabled={isLoading || availableLicenses === 0 ? true : false}
                                                                        onClick={() => handleSubmitFilter('available')}
                                                                        sx={isFilterStates.includes("available") ? btnStyles : btnStyle}
                                                                        variant="contained"
                                                                        className={isFilterStates.includes("available") ? "filter-list-button-active btn-inactive-color" : "filter-list-button button-color-inactive"}
                                                                        endIcon={
                                                                            <span className={isFilterStates.includes("available") ? "button-icon-active" : "button-icon "}>
                                                                                <Typography
                                                                                    variant="h5"
                                                                                    className={isFilterStates.includes("available") ? "filter-button-text-active " : "filter-button-text inactive-icon"}
                                                                                >
                                                                                    {availableLicenses ?? 0}
                                                                                </Typography>
                                                                            </span>
                                                                        }>
                                                                        Inactive
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        disabled={isLoading || expiredLicenses === 0 ? true : false}
                                                                        onClick={() => handleSubmitFilter('expired')}
                                                                        sx={isFilterStates.includes("expired") ? btnStyles : btnStyle}
                                                                        variant="contained"
                                                                        className={isFilterStates.includes("expired") ? "filter-list-button-active btn-expried-color" : "filter-list-button button-color-expried"}
                                                                        endIcon={
                                                                            <span className={isFilterStates.includes("expired") ? "button-icon-active" : "button-icon"}>
                                                                                <Typography
                                                                                    variant="h5"
                                                                                    className={isFilterStates.includes("expired") ? "filter-button-text-active " : "filter-button-text expried-icon "}
                                                                                >
                                                                                    {expiredLicenses ?? 0}
                                                                                </Typography>
                                                                            </span>
                                                                        }>
                                                                        Expired
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </ButtonGroup>
                                                    </div>
                                                </div>
                                                <Grid
                                                    className="sorting-button-group"
                                                    container
                                                    spacing={{ xs: 1, md: 1, lg: 1 }}
                                                    columns={{ xs: 2, sm: 4, md: 8 }}
                                                >
                                                    <Grid item>
                                                        <Button
                                                            disabled={isLoading === true ? true : false}
                                                            onClick={(e) => {
                                                                handleVisiblePurchaseLicenseModal(e)
                                                                getUserAvailableCredit()
                                                                getSavedStripeList()
                                                                getBrowserLicensePrices()
                                                            }}
                                                            sx={purchaselicenseStyle}
                                                            variant="contained"
                                                            className="filter-list-button-purchaselicense"
                                                        >
                                                            Purchase License
                                                        </Button>
                                                    </Grid>
                                                    <Grid item >
                                                        <div className="filter-list-button-select" >
                                                            <div className="grid-div-lic filterBoxlic" >
                                                                <TextField
                                                                    disabled={isLoading}
                                                                    id="outlined-controlled"
                                                                    variant="outlined"
                                                                    placeholder="Search By Nickname"
                                                                    fullWidth
                                                                    onChange={(e) => handleOnChanges(e)}
                                                                    onKeyDown={handleSerachKeyPress}
                                                                    value={nickname ?? ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <CardContent sx={{ marginTop: "-20px", }}>
                                            {!isLoading ? (
                                                licenseDataList && licenseDataList?.length > 0 ? (
                                                    currentData && currentData?.map((item, index) => {
                                                        const days = calculateDays(new Date(item?.validUpto ?? null), new Date());
                                                        return (
                                                            <Grid
                                                                key={index}
                                                                container
                                                                spacing={2}
                                                                sx={{
                                                                    width: "calc(100% + 41px) !important",
                                                                    marginTop: "15px",
                                                                    boxShadow: "0px 0px 2px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                                                                    borderRadius: "5px"
                                                                }}
                                                                className="graph-card-lic"
                                                            >
                                                                <Grid item xs={12} sm={12} lg={12} md={12}  >
                                                                    <CardContent
                                                                        sx={{
                                                                            padding: "0px!important",
                                                                            marginTop: "10px!important",
                                                                            display: "flex",
                                                                        }}
                                                                        className="plan-tbl vendor-outer"
                                                                    >
                                                                        <Grid item xs={6} sm={6} lg={6} md={6}>
                                                                            {isEditvisibleModel ? (
                                                                                <>
                                                                                    {licenseId === item?._id ? (
                                                                                        <Stack direction="row" className="avatar-icon" spacing={2} sx={{
                                                                                            padding: "0px 7px",
                                                                                        }} >
                                                                                            <Avatar
                                                                                                sx={{ bgcolor: "#002583 !important", color: "#fff" }}
                                                                                                alt="Remy Sharp"
                                                                                                src="/broken-image.jpg"
                                                                                            >
                                                                                                {!isEditNickNameLoading ?
                                                                                                    item?.nickname === (null || " ") ? "-" : item?.nickname?.slice(0, 1) ?? "-" :
                                                                                                    <CircularProgress
                                                                                                        size={24}
                                                                                                        color="inherit"

                                                                                                    />}
                                                                                            </Avatar>
                                                                                            <Typography
                                                                                                variant="h5"
                                                                                                sx={{
                                                                                                    color: "#000059!important",
                                                                                                    textAlign: (!item?.nickname || item?.nickname.trim() === '') ? "center" : "left",
                                                                                                    fontWeight: "600",
                                                                                                    fontSize: "22px",
                                                                                                    lineHeight: '41px',
                                                                                                    fontFamily: "Barlow!important"
                                                                                                }}
                                                                                            >
                                                                                                <TextField
                                                                                                    variant="outlined"
                                                                                                    fullWidth
                                                                                                    size="small"
                                                                                                    disabled={isEditNickNameLoading}
                                                                                                    onChange={(e) => handleInputChange(e)}
                                                                                                    onKeyDown={handleKeyPress}
                                                                                                    onBlur={handleUpdateNickNameOnBlur}
                                                                                                    value={!isEditNickNameLoading ? formData?.nickname ?? item?.nickname : item?.nickname}
                                                                                                    InputProps={{
                                                                                                        startAdornment: isEditNickNameLoading &&
                                                                                                            <CircularProgress
                                                                                                                size={24}
                                                                                                                sx={{
                                                                                                                    position: 'absolute',
                                                                                                                    top: '50%',
                                                                                                                    left: '50%',
                                                                                                                    marginTop: '-12px',
                                                                                                                    marginLeft: '-12px',
                                                                                                                }}
                                                                                                            />,
                                                                                                    }}
                                                                                                />
                                                                                            </Typography>
                                                                                        </Stack>
                                                                                    ) : (
                                                                                        <Stack direction="row" className="avatar-icon" spacing={2} sx={{
                                                                                            padding: "0px 7px",
                                                                                        }} >
                                                                                            <Avatar
                                                                                                sx={{ bgcolor: "#002583 !important", color: "#fff" }}
                                                                                                alt="Remy Sharp"
                                                                                                src="/broken-image.jpg"
                                                                                            >
                                                                                                {item?.nickname === (null || " ") ? "-" : item?.nickname?.slice(0, 1) ?? "-"}
                                                                                            </Avatar>
                                                                                            {item?.nickname && item?.nickname?.length > 6 ? (
                                                                                                <Typography
                                                                                                    variant="h5"
                                                                                                    sx={{
                                                                                                        color: "#000059!important",
                                                                                                        textAlign: (!item?.nickname || item?.nickname.trim() === '') ? "center" : "left",
                                                                                                        fontWeight: "600",
                                                                                                        fontSize: "22px",
                                                                                                        lineHeight: '41px',
                                                                                                        fontFamily: "Barlow!important"
                                                                                                    }}
                                                                                                >
                                                                                                    {item?.nickname === (null || " ") ? "-" : item?.nickname ?? "-"}
                                                                                                </Typography>
                                                                                            ) : (
                                                                                                <Typography
                                                                                                    variant="h5"
                                                                                                    sx={{
                                                                                                        width: "100%",
                                                                                                        maxWidth: "100px",
                                                                                                        color: "#000059!important",
                                                                                                        textAlign: (!item?.nickname || item?.nickname.trim() === '') ? "center" : "left",
                                                                                                        fontWeight: "600",
                                                                                                        fontSize: "22px",
                                                                                                        lineHeight: '41px',
                                                                                                        fontFamily: "Barlow!important"
                                                                                                    }}
                                                                                                >
                                                                                                    {item?.nickname === (null || " ") ? "-" : item?.nickname ?? "-"}
                                                                                                </Typography>
                                                                                            )}
                                                                                            <Tooltip title="Edit">
                                                                                                <IconButton
                                                                                                    sx={actionbutton}
                                                                                                    onClick={(e) => getSingleLicenseApi(item?._id)}
                                                                                                    aria-label="edit"
                                                                                                    size="small">
                                                                                                    <Edit fontSize="small" />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Stack>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {isValue && licenseId === item._id ?
                                                                                        <Stack direction="row" className="avatar-icon" spacing={2} sx={{
                                                                                            padding: "0px 7px",
                                                                                        }} >
                                                                                            <Avatar
                                                                                                sx={{ bgcolor: "#002583 !important", color: "#fff" }}
                                                                                                alt="Remy Sharp"
                                                                                                src="/broken-image.jpg"
                                                                                            >
                                                                                                {formData?.nickname === (null || " ") ? "-" : formData?.nickname?.slice(0, 1) ?? "-"}
                                                                                            </Avatar>
                                                                                            {formData?.nickname && formData?.nickname?.length > 6 ? (
                                                                                                <Typography
                                                                                                    variant="h5"
                                                                                                    sx={{
                                                                                                        color: "#000059!important",
                                                                                                        textAlign: (!formData?.nickname || formData?.nickname.trim() === '') ? "center" : "left",
                                                                                                        fontWeight: "600",
                                                                                                        fontSize: "22px",
                                                                                                        lineHeight: '41px',
                                                                                                        fontFamily: "Barlow!important"
                                                                                                    }}
                                                                                                >
                                                                                                    {formData?.nickname === (null || " ") ? "-" : formData?.nickname ?? "-"}
                                                                                                </Typography>
                                                                                            ) : (
                                                                                                <Typography
                                                                                                    variant="h5"
                                                                                                    sx={{
                                                                                                        width: "100%",
                                                                                                        maxWidth: "100px",
                                                                                                        color: "#000059!important",
                                                                                                        textAlign: (!formData?.nickname || formData?.nickname.trim() === '') ? "center" : "left",
                                                                                                        fontWeight: "600",
                                                                                                        fontSize: "22px",
                                                                                                        lineHeight: '41px',
                                                                                                        fontFamily: "Barlow!important"
                                                                                                    }}
                                                                                                >
                                                                                                    {formData?.nickname === (null || " ") ? "-" : formData?.nickname ?? "-"}
                                                                                                </Typography>
                                                                                            )}
                                                                                            <Tooltip title="Edit">
                                                                                                <IconButton
                                                                                                    sx={actionbutton}
                                                                                                    onClick={(e) => getSingleLicenseApi(item?._id)}
                                                                                                    aria-label="edit"
                                                                                                    size="small">
                                                                                                    <Edit fontSize="small" />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Stack>
                                                                                        :
                                                                                        <Stack direction="row" className="avatar-icon" spacing={2} sx={{
                                                                                            padding: "0px 7px",
                                                                                        }} >
                                                                                            <Avatar
                                                                                                sx={{ bgcolor: "#002583 !important", color: "#fff" }}
                                                                                                alt="Remy Sharp"
                                                                                                src="/broken-image.jpg"
                                                                                            >
                                                                                                {item?.nickname === (null || " ") ? "-" : item?.nickname?.slice(0, 1) ?? "-"}
                                                                                            </Avatar>
                                                                                            {item?.nickname && item?.nickname?.length > 6 ? (
                                                                                                <Typography
                                                                                                    variant="h5"
                                                                                                    sx={{
                                                                                                        color: "#000059!important",
                                                                                                        textAlign: (!item?.nickname || item?.nickname.trim() === '') ? "center" : "left",
                                                                                                        fontWeight: "600",
                                                                                                        fontSize: "22px",
                                                                                                        lineHeight: '41px',
                                                                                                        fontFamily: "Barlow!important"
                                                                                                    }}
                                                                                                >
                                                                                                    {item?.nickname === (null || " ") ? "-" : item?.nickname ?? "-"}
                                                                                                </Typography>
                                                                                            ) : (
                                                                                                <Typography
                                                                                                    variant="h5"
                                                                                                    sx={{
                                                                                                        width: "100%",
                                                                                                        maxWidth: "100px",
                                                                                                        color: "#000059!important",
                                                                                                        textAlign: (!item?.nickname || item?.nickname.trim() === '') ? "center" : "left",
                                                                                                        fontWeight: "600",
                                                                                                        fontSize: "22px",
                                                                                                        lineHeight: '41px',
                                                                                                        fontFamily: "Barlow!important"
                                                                                                    }}
                                                                                                >
                                                                                                    {item?.nickname === (null || " ") ? "-" : item?.nickname ?? "-"}
                                                                                                </Typography>
                                                                                            )}
                                                                                            <Tooltip title="Edit">
                                                                                                <IconButton
                                                                                                    sx={actionbutton}
                                                                                                    onClick={(e) => getSingleLicenseApi(item?._id)}
                                                                                                    aria-label="edit"
                                                                                                    size="small">
                                                                                                    <Edit fontSize="small" />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Stack>}
                                                                                </>
                                                                            )}
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6} lg={6} md={6} className="button-group">
                                                                            <div className="three-button" style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'flex-end',
                                                                                padding: '0px 15px',
                                                                            }}>
                                                                                {item?.license_data?.status === 'Expired' || item?.license_data?.status === 'Disabled' ? (
                                                                                    <Button
                                                                                        disabled={true}
                                                                                        type="button"
                                                                                        sx={{
                                                                                            backgroundColor: "#dddddd !important",
                                                                                            color: "#808080 !important",
                                                                                            textTransform: "none!important",
                                                                                            fontWeight: "bold",
                                                                                            fontFamily: "Barlow!important",
                                                                                            margin: "0 5px",
                                                                                            borderRadius: "4px!important",
                                                                                            minWidth: "max-content",
                                                                                            height: "max-content",
                                                                                            padding: "5px 10px",
                                                                                            border: "none !important"
                                                                                        }}
                                                                                        variant="contained"
                                                                                        className="card-btn-list-div"
                                                                                    >
                                                                                        Download
                                                                                    </Button>
                                                                                ) : (<Button
                                                                                    disabled={isDisabledDownload && item?._id === id}
                                                                                    onClick={() => handleDownloadBrowser(item?._id)}
                                                                                    type="button"
                                                                                    sx={{
                                                                                        backgroundColor: isDisabledDownload && item?._id === id ? "#dddddd !important" : "#002583 !important",
                                                                                        color: isDisabledDownload && item?._id === id ? "#808080 !important" : "#fff!important",
                                                                                        textTransform: "none!important",
                                                                                        fontWeight: "bold",
                                                                                        fontFamily: "Barlow!important",
                                                                                        margin: "0 5px",
                                                                                        borderRadius: "4px!important",
                                                                                        minWidth: "max-content",
                                                                                        height: "max-content",
                                                                                        padding: "5px 10px",
                                                                                        border: "none !important"
                                                                                    }}
                                                                                    variant="contained"
                                                                                    className="card-btn-list-div"
                                                                                >
                                                                                    {isDisabledDownload && item?._id === id ? <CircularProgress color="inherit" size={20} /> : "Download"}
                                                                                </Button>)}
                                                                                <Button
                                                                                    onClick={() => viewLogs(item?._id)}
                                                                                    type="button"
                                                                                    sx={{
                                                                                        backgroundColor: "#002583 !important",
                                                                                        color: "#fff!important",
                                                                                        textTransform: "none!important",
                                                                                        fontWeight: "bold",
                                                                                        fontFamily: "Barlow!important",
                                                                                        margin: "0 5px",
                                                                                        borderRadius: "4px!important",
                                                                                        minWidth: "max-content",
                                                                                        height: "max-content",
                                                                                        padding: "5px 10px",
                                                                                        border: "none !important"
                                                                                    }}
                                                                                    variant="contained"
                                                                                    className="card-btn-list-div"
                                                                                >
                                                                                    View Logs
                                                                                </Button>
                                                                                {((item?.license_data && item?.license_data?.status === 'Active') || item?.license_data?.status === 'Inactive') && (
                                                                                    <>
                                                                                        {item?.auto_renewal === "off" ? (
                                                                                            <Tooltip title={!item?.is_expired === true ? "" : "License is already unsubscribed."} arrow>
                                                                                                <span>
                                                                                                    <Button
                                                                                                        onClick={(e) => item?.is_expired === true ? handleVisibleReSubscribeLicnsesModal(e) : handleVisibleReSubscribeLicnsesModal(e, item)}
                                                                                                        type="button"
                                                                                                        sx={{
                                                                                                            backgroundColor: "#002583 !important",
                                                                                                            color: "#fff!important",
                                                                                                            textTransform: "none!important",
                                                                                                            fontWeight: "bold",
                                                                                                            fontFamily: "Barlow!important",
                                                                                                            margin: "0 5px",
                                                                                                            borderRadius: "4px!important",
                                                                                                            minWidth: "max-content",
                                                                                                            height: "max-content",
                                                                                                            padding: "5px 10px",
                                                                                                            border: "none !important"
                                                                                                        }}
                                                                                                        variant="contained"
                                                                                                        className="card-btn-list-div"
                                                                                                    >
                                                                                                        Resubscribe
                                                                                                    </Button>
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                        ) : (
                                                                                            <Button
                                                                                                onClick={(e) => handleVisibleUnsubscribeLicnsesModal(e, item._id, item)}
                                                                                                type="button"
                                                                                                sx={{
                                                                                                    backgroundColor: "#002583 !important",
                                                                                                    color: "#fff!important",
                                                                                                    textTransform: "none!important",
                                                                                                    fontWeight: "bold",
                                                                                                    fontFamily: "Barlow!important",
                                                                                                    margin: "0 5px",
                                                                                                    borderRadius: "4px!important",
                                                                                                    minWidth: "max-content",
                                                                                                    height: "max-content",
                                                                                                    padding: "5px 10px",
                                                                                                    border: "none !important"
                                                                                                }}
                                                                                                variant="contained"
                                                                                                className="card-btn-list-div"
                                                                                            >
                                                                                                Unsubscribe
                                                                                            </Button>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </Grid>
                                                                    </CardContent>
                                                                    <Grid
                                                                        className="text-listing-grid"
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "flex-end",
                                                                            alignItems: "center",
                                                                            padding: "0px 15px",
                                                                            marginTop: "20px!important",
                                                                            marginBottom: "25px!important",

                                                                        }}>
                                                                        <Grid item xs={2} sm={2} lg={2} md={2}
                                                                            className="license-key-text"
                                                                            sx={{
                                                                                padding: "0px 0px",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                maxWidth: "max-content",

                                                                            }}>
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    minWidth: "max-content !important",
                                                                                    textAlign: "center"
                                                                                }}
                                                                                className="license-validity-div"
                                                                            >
                                                                                <span className="table-text-heading license-key-heading">
                                                                                    License Key
                                                                                </span>
                                                                                <span className="table-text-heading-text">
                                                                                    <div className="container-tablecell-lic-key">
                                                                                        {item?.license_key?.length > 6 && (
                                                                                            <>
                                                                                                <section className="section-container">
                                                                                                    <span className="lic-key">
                                                                                                        {item?.license_key ?? null}
                                                                                                    </span>
                                                                                                    {isCopy ? (
                                                                                                        <>
                                                                                                            {item?.license_key === licenseKey ? (
                                                                                                                <LibraryAddCheck className="tooltip-container-checkCopy-icon" />
                                                                                                            ) : (
                                                                                                                <Tooltip
                                                                                                                    onClick={() => handleCopyToClipboard(item?.license_key)}
                                                                                                                    title="Copy"
                                                                                                                    className="tooltip-container"
                                                                                                                >
                                                                                                                    <RiFileCopy2Fill />
                                                                                                                </Tooltip>
                                                                                                            )}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <Tooltip
                                                                                                            onClick={() => handleCopyToClipboard(item?.license_key)}
                                                                                                            title="Copy"
                                                                                                            className="tooltip-container"
                                                                                                        >
                                                                                                            <RiFileCopy2Fill />
                                                                                                        </Tooltip>
                                                                                                    )}
                                                                                                </section>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </span>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={2} sm={2} lg={2} md={2}
                                                                            className="status-text"
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                maxWidth: "max-content",
                                                                            }}>
                                                                            <div className="license-validity-div  list-item-div">
                                                                                <span className="table-text-heading" >
                                                                                    Status
                                                                                </span>
                                                                                <span className="table-text-heading-text">
                                                                                    {item?.license_data && item?.license_data && (
                                                                                        <>
                                                                                            {item?.license_data && item.license_data?.status ? (
                                                                                                item?.license_data && item?.license_data?.status === 'Active' ? (
                                                                                                    <span style={{ color: '#01A65D' }} className="status-active-button">{item?.license_data?.status ?? ""}</span>
                                                                                                ) : (item?.license_data && item?.license_data?.status === 'Inactive') ? (
                                                                                                    <span style={{ color: '#FF6B00' }} className="status-Inactive-button">{item?.license_data?.status ?? ""}</span>
                                                                                                ) : (item?.license_data && item?.license_data?.status === 'Expired') ? (
                                                                                                    <span style={{ color: '#F11631' }} className="status-Expired-button">{item?.license_data?.status ?? ""}</span>
                                                                                                ) : (item?.license_data && item?.license_data?.status === 'Disabled') ? (
                                                                                                    <span style={{ color: '#3C4E6A' }} className="status-Disabled-button">{item?.license_data?.status ?? ""}</span>
                                                                                                ) : (null)
                                                                                            ) : (null)}
                                                                                        </>
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={2} sm={2} lg={2} md={2}
                                                                            className="license-type-text"
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                maxWidth: "max-content",

                                                                            }}>
                                                                            <div className="license-validity-div  list-item-div">
                                                                                <span className="table-text-heading" >
                                                                                    License Type
                                                                                </span>
                                                                                <span className="table-text-heading-text" style={{ marginRight: "20px" }}>
                                                                                    {handleCapitalize(item?.license_type) ?? null}
                                                                                </span>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={2} sm={2} lg={2} md={2}
                                                                            className="activations-text"
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                maxWidth: "max-content",

                                                                            }}>
                                                                            <div className="license-validity-div  list-item-div">
                                                                                <span className="table-text-heading" >
                                                                                    No. of Activations
                                                                                </span>
                                                                                <span className="table-text-heading-text" style={{ marginRight: "87px" }}>
                                                                                    {`${item?.license_data && (item?.license_data?.times_activated ?? null)}
                                                                                                                of
                                                                                           ${item?.license_data && (item?.license_data?.max_activations ?? null)} `}
                                                                                </span>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={2} sm={2} lg={2} md={2}
                                                                            className="generated-date-text"
                                                                        >
                                                                            <div className="license-validity-div  list-item-div">
                                                                                <span className="table-text-heading"
                                                                                >
                                                                                    Days Left
                                                                                </span>
                                                                                <span className="table-text-heading-text">
                                                                                    {item?.validUpto ?
                                                                                        (days === null || days > 0) ? (
                                                                                            (calculateDays(new Date(item?.validUpto ?? null), new Date() ?? null) + "  Days")
                                                                                        ) : ("Expired")
                                                                                        : ("-")}
                                                                                </span>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={2} sm={2} lg={2} md={2}
                                                                            className="activations-text"
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                maxWidth: "max-content",

                                                                            }}
                                                                        >
                                                                            <div className="license-validity-div  list-item-div">
                                                                                <span className="table-text-heading"
                                                                                >
                                                                                    Payment Method
                                                                                </span>
                                                                                <span className="table-text-heading-text">
                                                                                    {item?.cardInfo && item?.cardInfo?.card?.last4 ?
                                                                                        <>
                                                                                            XXXX {item?.cardInfo && item?.cardInfo?.card?.last4}
                                                                                        </>
                                                                                        : "N/A"}
                                                                                    <Tooltip
                                                                                        title="edit"
                                                                                        className="tooltip-container"
                                                                                        onClick={() =>
                                                                                            handleOpenChangeCardInfo(item?.cardInfo?.id, item._id)
                                                                                        }
                                                                                    >
                                                                                        <Edit />
                                                                                    </Tooltip>
                                                                                </span>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={2} sm={2} lg={2} md={2} className="expiry-date-text" >
                                                                            <div>
                                                                                <span className="table-text-heading">
                                                                                    Auto Renewal
                                                                                </span>
                                                                                <span className="table-text-heading-text"
                                                                                    style={{ marginRight: item?.validUpto ? "68px" : "135px" }}
                                                                                >
                                                                                    {item?.auto_renewal ?
                                                                                        (item?.auto_renewal === "on") ? (
                                                                                            <SwitchCustom
                                                                                                checked={item?.auto_renewal === "on" && true}
                                                                                                onChange={(e) => handleVisibleUnsubscribeLicnsesModal(e, item?._id, item)}
                                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                            />
                                                                                        ) : (item?.auto_renewal === "off") ? (
                                                                                            <SwitchCustom
                                                                                                onClick={(e) => item?.is_expired === true ? handleVisibleReSubscribeLicnsesModal(e) : handleVisibleReSubscribeLicnsesModal(e, item)}
                                                                                                checked={item?.auto_renewal === "off" && false}
                                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                            />
                                                                                        ) : ("-") : ("-")}
                                                                                </span>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} lg={6} md={6} className="icon-buttton-list">
                                                                        <div className="icon-three-button">
                                                                            {(item?.license_data?.status === 'Expired' || item?.license_data?.status === 'Disabled') ? (
                                                                                <Tooltip title="Download">
                                                                                    <IconButton
                                                                                        disabled={isDisabledDownload && item?._id === id}
                                                                                        sx={actionbutton}
                                                                                        size="small"
                                                                                        aria-label="download"
                                                                                    >
                                                                                        {isDisabledDownload && item?._id === id ? <CircularProgress color="inherit" size={20} /> : <Download fontSize="small" />}
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <Tooltip title="Download">
                                                                                    <IconButton
                                                                                        disabled={isDisabledDownload && item?._id === id}
                                                                                        onClick={() => handleDownloadBrowser(item?._id)}
                                                                                        sx={actionbutton}
                                                                                        size="small"
                                                                                        aria-label="download"
                                                                                    >
                                                                                        {isDisabledDownload && item?._id === id ? <CircularProgress color="inherit" size={20} /> : <Download fontSize="small" />}
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            )}
                                                                            <Tooltip title="View Log">
                                                                                <IconButton
                                                                                    onClick={() => viewLogs(item?._id)}
                                                                                    sx={actionbutton}
                                                                                    size="small"
                                                                                    aria-label="view-log"
                                                                                >
                                                                                    <img src={Historylog} alt="no" width={20} height={20} style={{ color: "#384E68" }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {((item?.license_data && item?.license_data?.status === 'Active') || item?.license_data?.status === 'Inactive') && (
                                                                                <>
                                                                                    {item?.auto_renewal === "off" ? (
                                                                                        <Tooltip title="License is already unsubscribed.">
                                                                                            <IconButton
                                                                                                onClick={(e) => item?.is_expired === true ? handleVisibleReSubscribeLicnsesModal(e) : handleVisibleReSubscribeLicnsesModal(e, item)}
                                                                                                sx={actionbutton}
                                                                                                size="small"
                                                                                                aria-label="Unsubscribe"
                                                                                            >
                                                                                                <Unsubscribe />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    ) : (
                                                                                        <Tooltip title="Unsubscribe">
                                                                                            <IconButton
                                                                                                onClick={(e) => handleVisibleUnsubscribeLicnsesModal(e, item?._id)}
                                                                                                sx={actionbutton}
                                                                                                size="small"
                                                                                                aria-label="Unsubscribe"
                                                                                            >
                                                                                                <Unsubscribe />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })) : (
                                                    <Grid container spacing={2}>
                                                        <Grid sm={12}>
                                                            <Typography
                                                                variant="h6"
                                                                sx={{
                                                                    fontSize: "1.6rem",
                                                                    textAlign: "center",
                                                                    padding: "60px 0px 0px 14px"
                                                                }}
                                                            >
                                                                No License Found
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )) : (
                                                <ContainerLoader />
                                            )}
                                            <br />
                                            {(licenseDataList && licenseDataList?.length > 0) && (
                                                <>
                                                    {totalRecords > 0 && (
                                                        <div className="pagnation-lic-div">
                                                            <CustomPagination
                                                                color="primary"
                                                                shape="rounded"
                                                                key="paginationnew"
                                                                className="custom-pagnation-item"
                                                                count={Math.ceil(totalRecords / pageLimit)}
                                                                page={searchPage}
                                                                onChange={onPageChanged}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="pagnation-item-parpage">
                                                        <label>Items per page :</label>
                                                        <FormControl
                                                            sx={{
                                                                m: 1,
                                                                minWidth: 50,
                                                            }}
                                                            size="small"
                                                        >
                                                            <Select
                                                                defaultValue={pageLimit}
                                                                value={pageLimit}
                                                                name="limit"
                                                                sx={{
                                                                    "& .MuiSelect-select": {
                                                                        padding: "4px 34px 4px 14px!important",
                                                                        fontFamily: "Barlow",
                                                                    },
                                                                }}
                                                                onChange={(e) => changePageLimit(e)}
                                                            >
                                                                <MenuItem value="10">10</MenuItem>
                                                                <MenuItem value="20">20</MenuItem>
                                                                <MenuItem value="50">50</MenuItem>
                                                                <MenuItem value="100">100</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </main>
                    </div>
                </div>
            </div >
            <Footer />
        </div>
        {/* {logs Modal} */}
        <Dialog
            scroll="body"
            fullWidth={true}
            maxWidth="sm"
            open={logsModal}
            className="Modal_Box custom-pop-over"
            onClose={hideLogsModal}
            TransitionComponent={Transition}
        >
            <DialogContent>
                <IconButton
                    aria-label="close"
                    onClick={() => hideLogsModal()}
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                    }}
                >
                    <Cancel />
                </IconButton>
                <Typography
                    variant="h6"
                    sx={{
                        width: "100%",
                        maxWidth: "14rem",
                        color: "#000059!important",
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "1.6rem",
                        fontFamily: "Barlow!important",
                    }}
                >
                    Logs
                </Typography>
                {loadLogs ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "11rem 0",
                        }}
                    >
                        <div style={{ position: 'relative', top: '4px' }}>
                            <img className="loader-size-table" src={loaderGif} alt="no loader" />
                        </div>
                    </Box>
                ) : (
                    <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCells>
                                            Status
                                        </StyledTableCells>
                                        <StyledTableCells>
                                            Date
                                        </StyledTableCells>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {licenselogs && licenselogs?.length > 0 ? (
                                        <>
                                            {licenselogs && licenselogs?.map((invlog, index) => {
                                                return (
                                                    <StyledTableRows key={index}>
                                                        <StyledTableCells component="td" scope="row">
                                                            {invlog?.status ?? null}
                                                        </StyledTableCells>
                                                        <StyledTableCells component="td" scope="row">
                                                            {customDateFormatLocal(
                                                                invlog?.createdAt,
                                                                null
                                                            )}
                                                        </StyledTableCells>
                                                    </StyledTableRows>

                                                )
                                            })}
                                        </>
                                    ) : (
                                        <StyledTableRows>
                                            <td
                                                colSpan={13}
                                                style={{ padding: "0rem" }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontSize: "1.6rem",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    No License Log yet!
                                                </Typography>
                                            </td>
                                        </StyledTableRows>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                {licenselogs && licenselogs?.length > 0 && (
                    <>
                        {licenseLogPagination?.totalRecords > 0 && (
                            <div className="pagnation-lic-div-logmodal">
                                <Pagination
                                    key="paginationnew"
                                    searchPage={licenseLogPagination?.searchPage}
                                    SearchDisable={SearchDisableLicenseLog}
                                    totalRecords={licenseLogPagination?.totalRecords}
                                    pageLimit={pageLimit}
                                    onPageChanged={onPageChangedLicenseLog}
                                />
                            </div>
                        )}
                        <div className="pagnation-item-parpage">
                            <label>Items per page :</label>
                            <FormControl
                                sx={{
                                    m: 1,
                                    minWidth: 50,
                                }}
                                size="small"
                            >
                                <Select
                                    defaultValue={pageLimit}
                                    value={pageLimit}
                                    name="limit"
                                    sx={{
                                        "& .MuiSelect-select": {
                                            padding: "4px 34px 4px 14px!important",
                                            fontFamily: "Barlow",
                                        },
                                    }}
                                    onChange={(e) => changePageLimitLicenseLog(e)}
                                >
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </>
                )}
            </DialogContent>
        </Dialog>
        {/*  Purchase License Modal */}
        <Dialog
            className="purchase-license-modal"
            onClick={handleBackdropClick}
            fullWidth={true}
            maxWidth="md"
            onClose={hideLogsModal}
            aria-labelledby="customized-dialog-title"
            open={isVisibleModelStates?.isVisiblePurchaseLicenseModal}
            TransitionComponent={Transition}
        >
            <div className="modal-purachse-licenses-scrollDiv">
                <DialogTitle id="customized-dialog-title"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: "17px 0px"
                    }}>
                    <Box sx={{ width: "100%" }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps?.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    className="modal-icon-button"
                    onClick={hideLogsModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    {(activeStep === 0) ? (
                        <React.Fragment>
                            {ispurchaseLoading ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "11rem 0",
                                    }}
                                >
                                    <div style={{ position: 'relative', top: '4px' }}>
                                        <img className="loader-size-table" src={loaderGif} alt="no loader" />
                                    </div>
                                </Box>
                            ) : (
                                <>
                                    <DialogTitle id="customized-dialog-title" sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: "7px 14px"
                                    }}>
                                        Product Details
                                        <Typography
                                            variant="h5"
                                            className="purchase-license-typography"
                                        >
                                            <span className="Account_details_box-lic" >Available Credit :</span>
                                            {isPrimaryLicenseAdded === true ? (
                                                <span style={{ fontWeight: "600", color: userAvailableCredit < parseInt(browserLicensePrice?.secondary) ? "red" : "#00855D" }}>
                                                    {' '}{changeDollarAmountformat(userAvailableCredit) ?? "0.00"}
                                                </span>
                                            ) : (
                                                <span style={{ fontWeight: "600", color: userAvailableCredit < parseInt(browserLicensePrice?.primary) ? "red" : "#00855D" }}>
                                                    {' '}{changeDollarAmountformat(userAvailableCredit) ?? "0.00"}
                                                </span>
                                            )}
                                        </Typography>
                                    </DialogTitle>
                                    <Typography className="product-text-btn" sx={{ display: "flex" }}>
                                        <span className="btn-span">
                                            {isPrimaryLicenseAdded === true ? (
                                                <>
                                                    {userAvailableCredit >= (cartItemSecondary.totalAmountPaid) ?
                                                        ("")
                                                        :
                                                        (<>
                                                            <Button
                                                                component={Link}
                                                                to='/purchase-credit'
                                                                variant="contained"
                                                                type="submit"
                                                                size="small"
                                                                sx={purchasebtnmodalStylePurchaeCredit}
                                                                onClick={() => localStorage?.setItem('licensePageUrl', 'true')}
                                                            >
                                                                Purchase Credit
                                                            </Button>
                                                        </>)
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {userAvailableCredit >= (cartItem?.totalAmountPaidSecondary + cartItem?.totalAmountPaidPrimary) ?
                                                        ("")
                                                        :
                                                        (<>
                                                            <Button
                                                                component={Link}
                                                                to='/purchase-credit'
                                                                variant="contained"
                                                                type="submit"
                                                                size="small"
                                                                sx={purchasebtnmodalStylePurchaeCredit}
                                                                onClick={() => localStorage?.setItem('licensePageUrl', 'true')}
                                                            >
                                                                Purchase Credit
                                                            </Button>
                                                        </>)
                                                    }
                                                </>)}
                                        </span>
                                    </Typography>
                                    <DialogContent>
                                        <ValidatorForm>
                                            <Typography>
                                                <span className="Account_details_box-lic  purchase-license-modal-text">
                                                    <span className="purchase-license-modal-heading">Anonymous Browser Pricing</span>
                                                </span>
                                                <span style={{ color: " #858C96" }}>
                                                    <span>
                                                        Primary License {" "}:{' '}{changeDollarAmountformat(browserLicensePrice?.primary_price)}/month
                                                    </span>
                                                    <span style={{ paddingLeft: "30px", borderRight: "1px solid gray" }}></span>
                                                    <span style={{ paddingLeft: "30px" }}>
                                                        Secondary License {" "}:{' '}{changeDollarAmountformat(browserLicensePrice?.secondary)}/month
                                                    </span>
                                                </span>
                                            </Typography>
                                            <br />
                                            <TableContainer>
                                                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="purtablecell">Product</TableCell>
                                                            <TableCell className="purtablecell" sx={{ paddingLeft: "53px!important" }}>Quantity</TableCell>
                                                            <TableCell className="purtablecell" >Total</TableCell>
                                                            <TableCell className="purtablecell" ></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {!isPrimaryLicenseAdded === true ? (
                                                            <>
                                                                <TableRow
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell scope="row">
                                                                        Anonymous Browser - Primary
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        <div className="btn_div">
                                                                            <Button
                                                                                disabled={cartItem?.quantityPrimary === 1}
                                                                                className="addbutton"
                                                                                onClick={handleDecrementQuantity}
                                                                            >
                                                                                <Remove />
                                                                            </Button>
                                                                            <div className="count-h1">
                                                                                <TextValidator
                                                                                    className="quantity-input"
                                                                                    sx={{
                                                                                        input: {
                                                                                            padding: "12px 12px",
                                                                                            "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                                                                                                "-webkit-appearance": "none",
                                                                                                margin: 0,
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    type="number"
                                                                                    value={cartItem?.quantityPrimary ?? ""}
                                                                                    name="quantity"
                                                                                    placeholder="Enter Quantity"
                                                                                    validators={["required"]}
                                                                                    errorMessages={["This field is required"]}
                                                                                />
                                                                            </div>
                                                                            <Button
                                                                                className="addbutton"
                                                                                onClick={handleIncrementQuantity}
                                                                                disabled={cartItem?.quantityPrimary === 1}
                                                                            >
                                                                                <AddCircleOutline />
                                                                            </Button>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        <b>{changeDollarAmountformat(cartItem?.totalAmountPaidPrimary?.toFixed(2)) ?? "0.00"}/month</b>
                                                                    </TableCell>
                                                                </TableRow>
                                                                {/* Secondary */}
                                                                <TableRow
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell scope="row">Anonymous Browser - Secondary</TableCell>
                                                                    <TableCell >
                                                                        <div className="btn_div">
                                                                            <Button
                                                                                className="addbutton"
                                                                                onClick={handleDecrementSecondary}
                                                                                disabled={cartItem?.quantitySecondary === 0}
                                                                            >
                                                                                <Remove />
                                                                            </Button>
                                                                            <div className="count-h1">
                                                                                <TextValidator
                                                                                    className="quantity-input"
                                                                                    sx={{
                                                                                        input: {
                                                                                            padding: "12px 12px",
                                                                                            "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                                                                                                "-webkit-appearance": "none",
                                                                                                margin: 0,
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    type="number"
                                                                                    value={cartItem?.quantitySecondary}
                                                                                    name="quantity"
                                                                                    placeholder="Enter Quantity"
                                                                                    validators={["required"]}
                                                                                    errorMessages={["This field is required"]}
                                                                                />
                                                                            </div>
                                                                            <Button
                                                                                className="addbutton"
                                                                                onClick={handleIncrementSecondary}
                                                                            >
                                                                                <AddCircleOutline />
                                                                            </Button>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <b>{changeDollarAmountformat(cartItem?.totalAmountPaidSecondary?.toFixed(2)) ?? "0.00"}/month</b>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TableRow
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell scope="row">
                                                                        Anonymous Browser - Secondary
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        <div className="btn_div">
                                                                            <Button
                                                                                disabled={cartItemSecondary?.quantity === 1}
                                                                                className="addbutton"
                                                                                onClick={handleDecrementQuantitySecondary}
                                                                            >
                                                                                <Remove />
                                                                            </Button>
                                                                            <div className="count-h1">
                                                                                <TextValidator
                                                                                    className="quantity-input"
                                                                                    sx={{
                                                                                        input: {
                                                                                            padding: "12px 12px",
                                                                                            "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                                                                                                "-webkit-appearance": "none",
                                                                                                margin: 0,
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    type="number"
                                                                                    value={cartItemSecondary?.quantity}
                                                                                    name="quantity"
                                                                                    placeholder="Enter Quantity"
                                                                                    validators={["required"]}
                                                                                    errorMessages={["This field is required"]}
                                                                                />
                                                                            </div>
                                                                            <Button className="addbutton" onClick={handleIncrementQuantitySecondary}>
                                                                                <AddCircleOutline />
                                                                            </Button>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <b>{changeDollarAmountformat(cartItemSecondary?.totalAmountPaid?.toFixed(2)) ?? "0.00"}/month</b>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        )}
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        />
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Typography>
                                                <span>
                                                    License duration {" "}:{" "} 30 Days
                                                </span>
                                                <span style={{ paddingLeft: "30px", borderRight: "1px solid gray" }}></span>
                                                <span style={{ paddingLeft: "30px" }}>
                                                    Auto-Renewal {" "}:{" "} On
                                                </span>
                                            </Typography>
                                            <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                                <span className="purchase-license-modal-heading">
                                                    {isPrimaryLicenseAdded === true ? (
                                                        <>
                                                            {userAvailableCredit >= cartItemSecondary?.totalAmountPaid ?
                                                                ("")
                                                                :
                                                                (
                                                                    <span style={{ color: "red" }}>
                                                                        You don't have enough funds. Please purchase credit before purchasing the license(s).
                                                                    </span>
                                                                )
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            {userAvailableCredit >= (cartItem?.totalAmountPaidSecondary + cartItem?.totalAmountPaidPrimary) ?
                                                                ("")
                                                                :
                                                                (
                                                                    <span style={{ color: "red" }}>
                                                                        You don't have enough funds. Please purchase credit before purchasing the license(s).
                                                                    </span>
                                                                )
                                                            }
                                                        </>)}
                                                </span>
                                            </span>
                                        </ValidatorForm>
                                    </DialogContent>
                                </>)}
                        </React.Fragment>
                    ) : (activeStep === 1) ? (
                        <React.Fragment>
                            <DialogTitle id="customized-dialog-title"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: "6px 12px"
                                }}>
                                Choose Backup Payment Method
                                <img style={{
                                    width: "123px",
                                    height: "30px",
                                    marginRight: "190px",
                                    marginTop: "4px",
                                }}
                                    src={Stripe}
                                    alt="stripe"
                                />
                                {stripeCardList && stripeCardList?.length > 0 ? (
                                    <Typography
                                        variant="h5"
                                    >
                                        <span className="Account_details_box-lic" >
                                            <LoadingButton
                                                onClick={() => getSavedStripeList()}
                                                size="small"
                                                loading={ispurchaseLoading}
                                                endIcon={ispurchaseLoading ? (<CircularProgress color="inherit" size={20} />) : (<Loop />)}
                                                disabled={ispurchaseLoading}
                                                loadingPosition="end"
                                                variant="contained"
                                            >
                                                Refresh
                                            </LoadingButton>
                                        </span>
                                    </Typography>
                                ) : (null)}
                            </DialogTitle>
                            <DialogContent>
                                <div>
                                    <FormControl className="saved-card-details">
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={selectedCard}
                                            name="radio-buttons-group"
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Item>
                                                        <span className="Account_details_box-lic purchase-license-modal-texttwo heading-modal-choose-payment">
                                                            <span className="purchase-lic-modal-heading2"
                                                                style={{
                                                                    fontSize: "15px",
                                                                    padding: "0px 0px 9px 0px"
                                                                }}>
                                                                Saved Cards
                                                            </span>
                                                        </span>
                                                        <div class="inner-wrapper-purchase-license">
                                                            {!ispurchaseLoading ? (
                                                                <>
                                                                    {stripeCardList && stripeCardList?.length > 0 ? (
                                                                        <>
                                                                            {stripeCardList && stripeCardList?.map((item, index) => (
                                                                                <div className="credit-card-inner">
                                                                                    <div className={`credit-card ${selectedCard === item.id ? 'selected' : ''}`} onClick={() => handleCardClick(item.id)}>
                                                                                        <FormControlLabel
                                                                                            key={item.id}
                                                                                            value={item.id}
                                                                                            control={<Radio sx={{ marginTop: "6px" }} />}
                                                                                            sx={{ width: '100%' }}
                                                                                            label={
                                                                                                <Typography variant="body5" color="textSecondary" sx={{ width: "100%" }}>
                                                                                                    <div className="content">
                                                                                                        <div className="content-card-name">
                                                                                                            <div>Card Holder : {item?.name ?? null}</div>
                                                                                                            <div>{(item?.card && item?.card?.brand?.toUpperCase()) ?? null}</div>
                                                                                                        </div>
                                                                                                        <div className="content-card-detail">
                                                                                                            <span className="card-number">XXXX XXXX XXXX {(item?.card && item?.card?.last4) ?? null}</span>
                                                                                                            <span>Expiry Date : {(item?.card && item?.card?.exp_month) ?? null}/{(item?.card && item?.card?.exp_year) ?? null}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Typography>
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    &nbsp;&nbsp;
                                                                                    <div className="credit-card-delete">
                                                                                        <Stack direction="row" spacing={1}>
                                                                                            <Tooltip title="Delete">
                                                                                                <IconButton aria-label="delete" onClick={() => handleVisableDeleteConfimationModal(item.id)}>
                                                                                                    <Delete />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Stack>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                    ) : (
                                                                        <div>
                                                                            <td
                                                                                colSpan={13}
                                                                                style={{ padding: "0.6rem 17rem 25px" }}
                                                                            >
                                                                                <Typography
                                                                                    variant="h6"
                                                                                    sx={{
                                                                                        fontSize: "1.6rem",
                                                                                        textAlign: "center",
                                                                                    }}
                                                                                >
                                                                                    No Card Found
                                                                                </Typography>
                                                                            </td>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        padding: "8rem 0",
                                                                    }}
                                                                >
                                                                    <div style={{ position: 'relative', top: '4px' }}>
                                                                        <img className="loader-size-table" src={loaderGif} alt="no loader" />
                                                                    </div>
                                                                </Box>
                                                            )}
                                                        </div>
                                                    </Item>
                                                    {stripeCardList && stripeCardList?.length > 0 ? (
                                                        <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                                            <span className="purchase-lic-modal-heading2"
                                                                style={{
                                                                    fontSize: "15px",
                                                                    color: "red !important"
                                                                }}>
                                                                Note: Please hit refresh above to get the latest saved cards
                                                            </span>
                                                        </span>
                                                    ) : (null)}
                                                </Grid>
                                                <Grid item xs={12} sx={{ paddingTop: "0px!important", paddingBottom: "10px !important" }}>
                                                    <Item>
                                                        <span className="Account_details_box-lic purchase-license-modal-texttwo heading-modal-choose-payment">
                                                            <span className="purchase-lic-modal-heading2"
                                                                style={{
                                                                    fontSize: "15px",
                                                                    padding: "0px 0px 2px 0px"
                                                                }}>
                                                                Add Payment Method
                                                            </span>
                                                        </span>
                                                        <div className={`credit-card credit-card-other-option ${selectedCard === 'other' ? 'selected' : ''}`} onClick={() => handleCardClick('other')}>
                                                            <FormControlLabel
                                                                value={selectedCard === "other" ? "other" : ""}
                                                                control={<Radio sx={{ marginTop: "6px" }} />}
                                                                sx={{ width: '100%' }}
                                                                label={
                                                                    <Typography variant="body5" color="textSecondary" sx={{ width: "100%" }}>
                                                                        <div className="content">
                                                                            <div className="content-card-name">
                                                                                <div className="other-text" >Other</div>
                                                                            </div>
                                                                        </div>
                                                                    </Typography>
                                                                }
                                                            />
                                                        </div>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </DialogContent>
                        </React.Fragment>
                    ) : (activeStep === 2) ? (
                        <React.Fragment>
                            <DialogTitle id="customized-dialog-title" sx={{ padding: '0px 0px 0px 12px' }}>
                                Order Confirmation
                                <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                    <span className="purchase-lic-modal-heading2"
                                        style={{
                                            fontSize: "15px",
                                            marginTop: "-8px",
                                            padding: "0px 0px 16px"
                                        }}>
                                        Note: All charges will be deducted first from Ticket Liberty Credit.
                                    </span>
                                </span>
                            </DialogTitle>
                            <DialogContent>
                                <TableContainer>
                                    <Table>
                                        <TableBody sx={{ marginBottom: 3 }}>
                                            <Typography sx={{ fontWeight: 700, fontSize: 21, marginBottom: 1 }}>Order Totals</Typography>
                                            <TableRow>
                                                <TableCell sx={{ width: "40%" }} component="th" scope="row">
                                                    Total Due Now
                                                </TableCell>
                                                <TableCell >
                                                    <b>{isPrimaryLicenseAdded === true ?
                                                        changeDollarAmountformat((cartItemSecondary?.totalAmountPaid)?.toFixed(2))
                                                        :
                                                        changeDollarAmountformat((cartItem?.totalAmountPaidSecondary + cartItem?.totalAmountPaidPrimary).toFixed(2))}</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <br />
                                        <TableBody>
                                            <Typography sx={{ fontWeight: 700, fontSize: 21, marginBottom: 1 }}>Recurring Totals</Typography>
                                            <TableRow>
                                                <TableCell sx={{ width: "40%" }} component="th" scope="row">
                                                    Recurring Total
                                                </TableCell>
                                                <TableCell>
                                                    <b>
                                                        {isPrimaryLicenseAdded === true ? (
                                                            changeDollarAmountformat((cartItemSecondary?.totalAmountPaid)?.toFixed(2))
                                                        ) : (isbrowserChargeDate ? (
                                                            changeDollarAmountformat(cartItem?.totalAmountPaidSecondary + parseFloat(browserLicensePrice?.primary_price))
                                                        ) : (
                                                            changeDollarAmountformat(cartItem?.totalAmountPaidPrimary + cartItem?.totalAmountPaidSecondary)))
                                                        }</b>{" "}/{" "}month
                                                    <br />
                                                    <span>
                                                        {isbrowserChargeDate ? (
                                                            <>
                                                                First automatic renewal will be charged 30 days after activation.
                                                                < br />
                                                                <span style={{ fontWeight: 500, fontSize: 'inherit' }}>
                                                                    Note: For the primary license, it will be charged after 30 days of activation starting after {browerCharge?.browser_charge_date ? new Intl.DateTimeFormat('en', options).format(new Date(browerCharge?.browser_charge_date ?? null)) : null}.
                                                                </span>
                                                            </>
                                                        ) : (
                                                            "First automatic renewal will be charged 30 days after activation."
                                                        )}<br />
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox onChange={(e) => handleAgreeToAnonymousBrowserChecked(e)} value={isAgreeAnonymousBrowserChecked} />}
                                            label={<Link passHref target="_blank" to="/end-user-license-agreement">I agree to the Anonymous Browser End User License Agreement.</Link>} />
                                    </FormGroup>
                                    <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                        <span className="purchase-license-modal-heading">
                                            Note: Auto-renewal has been defaulted on.Turn off Auto-renewal to unsubscribe. If your Ticket Liberty credit is below the recurring charge, we will charge your credit card in full.
                                        </span>
                                    </span>
                                </TableContainer>
                            </DialogContent>
                        </React.Fragment>
                    ) : (null)}
                </DialogContent>
                <DialogActions sx={{ padding: "0px 16px 10px 0px !important" }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        {(activeStep === 0) ? (
                            <Button
                                onClick={hideLogsModal}
                                variant="contained"
                                type="button"
                                size="small"
                                sx={purchasebtnmodalStyle}
                            >
                                Cancel
                            </Button>
                        ) : (
                            <>
                                {(activeStep === 2) ? (
                                    <>
                                        {isVisibleModelStates?.isDisabledPurchaseLicenseButton === true ? (
                                            <LoadingButton
                                                size="large"
                                                disabled={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                className={`${isVisibleModelStates?.isDisabledPurchaseLicenseButton ?
                                                    'theme-btn-generate-licenses-disabled-pur-lic' : "theme-btn-generate-licenses-pur-lic"}`}
                                                loadingPosition="end"
                                                variant="contained"
                                            >
                                                Back
                                            </LoadingButton>
                                        ) : (
                                            <Button
                                                disabled={activeStep === 0 || isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                onClick={handleBack}
                                                variant="contained"
                                                type="button"
                                                size="small"
                                                sx={purchasebtnmodalStyle}
                                            >
                                                Back
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        disabled={activeStep === 0 || isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                        onClick={handleBack}
                                        variant="contained"
                                        type="button"
                                        size="small"
                                        sx={purchasebtnmodalStyle}
                                    >
                                        Back
                                    </Button>
                                )}
                            </>
                        )}
                        &nbsp;&nbsp;&nbsp;
                        <Box sx={{ flex: "1 1 auto" }} />
                        {(isPrimaryLicenseAdded === true) ? (
                            <>
                                {(userAvailableCredit >= cartItemSecondary?.totalAmountPaid) ? (
                                    <>
                                        {activeStep === 1 ? (
                                            <>
                                                {(selectedCard === "other" || !selectedCard) ? (
                                                    <LoadingButton
                                                        onClick={() => handleAddStripeCustomPayMethod()}
                                                        size="large"
                                                        loading={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                        endIcon={isVisibleModelStates?.isDisabledPurchaseLicenseButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                        disabled={(isVisibleModelStates?.isDisabledPurchaseLicenseButton || !selectedCard)}
                                                        className={`${(isVisibleModelStates?.isDisabledPurchaseLicenseButton || !selectedCard) ?
                                                            'add-payment-method-strip-btn-diable' : "add-payment-method-strip-btn-enable"}`}
                                                        loadingPosition="end"
                                                        variant="contained"
                                                    >
                                                        Next
                                                    </LoadingButton>
                                                ) : (
                                                    <>
                                                        {(activeStep === 2) ? (
                                                            <LoadingButton
                                                                onClick={() => handlePurchaseLicense()}
                                                                size="large"
                                                                loading={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                                endIcon={isVisibleModelStates?.isDisabledPurchaseLicenseButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                                disabled={(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isAgreeAnonymousBrowserChecked === false ? true : false)}
                                                                className={`${(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isAgreeAnonymousBrowserChecked === false ? true : false) ?
                                                                    'add-payment-method-strip-btn-diable' : "add-payment-method-strip-btn-enable"}`}
                                                                loadingPosition="end"
                                                                variant="contained"
                                                            >
                                                                Confirm
                                                            </LoadingButton>
                                                        ) : (
                                                            <Button
                                                                disabled={userAvailableCredit >= cartItemSecondary?.totalAmountPaid ? false : true
                                                                }
                                                                variant="contained"
                                                                type="submit"
                                                                onClick={() => handleNext()}
                                                                size="small"
                                                                sx={purchasebtnmodalStyle}
                                                            >
                                                                Next
                                                            </Button>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {(activeStep === 2) ? (
                                                    <LoadingButton
                                                        onClick={() => handlePurchaseLicense()}
                                                        size="large"
                                                        loading={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                        endIcon={isVisibleModelStates?.isDisabledPurchaseLicenseButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                        disabled={(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isAgreeAnonymousBrowserChecked === false ? true : false)}
                                                        className={`${(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isAgreeAnonymousBrowserChecked === false ? true : false) ?
                                                            'add-payment-method-strip-btn-diable' : "add-payment-method-strip-btn-enable"}`}
                                                        loadingPosition="end"
                                                        variant="contained"
                                                    >
                                                        Confirm
                                                    </LoadingButton>
                                                ) : (
                                                    <Button
                                                        disabled={userAvailableCredit >= cartItemSecondary?.totalAmountPaid ? false : true
                                                        }
                                                        variant="contained"
                                                        type="submit"
                                                        onClick={handleNext}
                                                        size="small"
                                                        sx={purchasebtnmodalStyle}
                                                    >
                                                        Next
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        size="small"
                                        disabled={userAvailableCredit >= cartItemSecondary?.totalAmountPaid ? false : true}
                                        className="theme-btn-generate-licenses-disabled-purchase"
                                    >
                                        Next
                                    </Button>
                                )}
                            </>
                        ) : (
                            <>
                                {userAvailableCredit >= (cartItem?.totalAmountPaidSecondary + cartItem?.totalAmountPaidPrimary) ? (
                                    <>
                                        {(activeStep === 1) ? (
                                            <>
                                                {(selectedCard === "other" || !selectedCard) ? (
                                                    <LoadingButton
                                                        onClick={() => handleAddStripeCustomPayMethod()}
                                                        size="large"
                                                        loading={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                        endIcon={isVisibleModelStates?.isDisabledPurchaseLicenseButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                        disabled={(isVisibleModelStates?.isDisabledPurchaseLicenseButton || !selectedCard)}
                                                        className={`${(isVisibleModelStates?.isDisabledPurchaseLicenseButton || !selectedCard) ?
                                                            'add-payment-method-strip-btn-diable' : "add-payment-method-strip-btn-enable"}`}
                                                        loadingPosition="end"
                                                        variant="contained"
                                                    >
                                                        Next
                                                    </LoadingButton>
                                                ) : (
                                                    <>
                                                        {(activeStep === 2) ? (
                                                            <LoadingButton
                                                                onClick={() => handlePurchaseLicense()}
                                                                size="large"
                                                                loading={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                                endIcon={isVisibleModelStates?.isDisabledPurchaseLicenseButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                                disabled={(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isAgreeAnonymousBrowserChecked === false ? true : false)}
                                                                className={`${(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isAgreeAnonymousBrowserChecked === false ? true : false) ?
                                                                    'add-payment-method-strip-btn-diable' : "add-payment-method-strip-btn-enable"}`}
                                                                loadingPosition="end"
                                                                variant="contained"
                                                            >
                                                                Confirm
                                                            </LoadingButton>
                                                        ) : (
                                                            <Button
                                                                disabled={userAvailableCredit >= (cartItem?.totalAmountPaidSecondary + cartItem?.totalAmountPaidPrimary) ? false : true}
                                                                variant="contained"
                                                                type="submit"
                                                                onClick={handleNext}
                                                                size="small"
                                                                sx={purchasebtnmodalStyle}
                                                            >
                                                                Next
                                                            </Button>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {(activeStep === 2) ? (
                                                    <LoadingButton
                                                        onClick={() => handlePurchaseLicense()}
                                                        size="large"
                                                        loading={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                        endIcon={isVisibleModelStates?.isDisabledPurchaseLicenseButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                        disabled={(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isAgreeAnonymousBrowserChecked === false ? true : false)}
                                                        className={`${(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isAgreeAnonymousBrowserChecked === false ? true : false) ?
                                                            'add-payment-method-strip-btn-diable' : "add-payment-method-strip-btn-enable"}`}
                                                        loadingPosition="end"
                                                        variant="contained"
                                                    >
                                                        Confirm
                                                    </LoadingButton>
                                                ) : (
                                                    <Button
                                                        disabled={userAvailableCredit >= (cartItem?.totalAmountPaidSecondary + cartItem?.totalAmountPaidPrimary) ? false : true}
                                                        variant="contained"
                                                        type="submit"
                                                        onClick={handleNext}
                                                        size="small"
                                                        sx={purchasebtnmodalStyle}
                                                    >
                                                        Next
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        size="small"
                                        disabled={userAvailableCredit >= (cartItem?.totalAmountPaidSecondary + cartItem?.totalAmountPaidPrimary) ? false : true}
                                        className="theme-btn-generate-licenses-disabled-purchase"
                                    >
                                        Next
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>
                </DialogActions>
            </div>
        </Dialog >
        {/* payment  successfull. modal */}
        <Dialog
            onClick={handleBackdropClick}
            aria-labelledby="customized-dialog-title"
            open={isVisibleModelStates?.isVisiblePaymentSuccessfull}
        >
            <DialogContent>
                <Typography sx={{
                    color: "#000059!important",
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "25px",
                    marginLeft: '1px',
                    lineHeight: '17.4px',
                    fontFamily: "Barlow!important",
                }}>
                    <CheckCircle sx={{ width: '371px', height: "103px", color: 'green' }} />
                </Typography>
                <Typography sx={{
                    color: "#000059!important",
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "20px",
                    marginLeft: '1px',
                    lineHeight: '26.4px',
                    fontFamily: "Barlow!important",
                    paddingBottom: '9px'
                }}>
                    Payment Successful
                </Typography>
                <Typography sx={{
                    color: "#000059!important",
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "18px",
                    marginLeft: '1px',
                    width: "415px",
                    lineHeight: '26.4px',
                    fontFamily: "Barlow!important",
                }}>
                    License(s) Created Successfully <br /> Your Ticket Liberty account has been charged.
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', marginBottom: '15px' }}>
                <Button sx={modalbtnStyle} onClick={handleClosePaymentSuccessfull}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
        {/* {unsubscribe Licnses Confmation modal} */}
        <ConfirmationModal
            handleAPIRequest={valiupData?.license_type === "primary" ? handleOpenSecondPrimaryLicense : handleUnsubscribeLicenses}
            isOpen={isVisibleunsubscribeLicConfirmModal}
            handleClose={handleCloseUnsubscribeLicnsesModal}
            message={
                <>
                    <p className="unsubcribe">
                        By turning off auto-renewal, the subscription license will no longer be valid effective {" "}
                        {!valiupData?.validUpto ? "after 30 days of activation" : "on " + new Intl.DateTimeFormat('en', options).format(new Date(valiupData?.validUpto ?? null))}.
                        Are you sure you want to cancel your subscription?
                    </p>
                    {valiupData?.license_type === "primary" &&
                        <p className="note-message">
                            Note: Please note that if you unsubscribe from the primary license, you will be unsubscribing from all licenses on this account.
                            If you have an questions or concerns, please contact us at <a href=".">browser@ticketliberty.com. </a>
                        </p>
                    }
                </>
            }
            title={"Unsubscribe License"}
            buttonText={valiupData?.license_type === "primary" ? "Next" : "Continue"}
            buttonText2={"Cancel"}
        />
        {/* {unsubscribe Licnses Primary second Confmation modal} */}
        <ConfirmationModal
            handleAPIRequest={handleUnsubscribeLicenses}
            isOpen={isSecondPrimaryConfirmModal}
            handleClose={handleCloseSecondPrimaryLicense}
            message={"Are you sure you want to continue?"}
            title={"Unsubscribe License"}
            buttonText={"Continue"}
            buttonText2={"Cancel"}
        />
        {/* {ReSubscribe Licnses Confmation modal} */}
        <ConfirmationModal
            handleAPIRequest={handleReSubscribeLicense}
            isOpen={isVisibleModelStates?.isVisibleResubscribeLicConfirmModal}
            handleClose={handleCloseUnsubscribeLicnsesModal}
            message={
                <p className="unsubcribe">
                    {message}
                </p>
            }
            title={"Resubscribe License"}
            buttonText={"Continue"}
            buttonText2={"Cancel"}
        />
        {/* {delete conmation modal} */}
        <ConfirmationModal
            handleAPIRequest={handleDeleteStripePaymentMethod}
            isOpen={isDeleteModalShow}
            handleClose={handleCloseDeleteModal}
            message={"Are you sure you want to delete this payment method?"}
            title={"Delete"}
            buttonText={"Delete"}
            buttonText2={"Cancel"}
        />
        {/* change payment method cardinfo  modal */}
        <Dialog
            className="purchase-license-modal"
            fullWidth={true}
            maxWidth="md"
            aria-labelledby="customized-dialog-title"
            TransitionComponent={Transition}
            open={isOpenChangeCardInfo}
            onClick={handleBackdropClick}
            onClose={hnadleCloseChangeCardInfo}
        >
            <ChangePaymentMethod
                handleVisableDeleteConfimationModal={handleVisableDeleteConfimationModal}
                hnadleCloseChangeCardInfo={hnadleCloseChangeCardInfo}
                stripeCardList={stripeCardList}
                getSavedStripeList={getSavedStripeList}
                ispurchaseLoading={ispurchaseLoading}
                handleOnChangeCardInfo={handleOnChangeCardInfo}
                selectedCardInfo={selectedCardInfo}
                handleUpdateLicense={handleUpdateLicense}
                handleAddStripeCustomPayMethod={handleStripeCustomPayChangeCardInfo}
            />
        </Dialog>
        {/* alert message */}
        <AlertMessage
            open={alertMessage.openAlert}
            message={alertMessage.message}
            severity={alertMessage.alertSeverity}
            closeCall={hideAlert}
        />
    </>
};
export default NewLicensesSummary;