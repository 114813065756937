
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

// mui components
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Slide,
    Stack
    , Step
    , StepLabel,
    Stepper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled
} from '@mui/material'

// mui icon components
import { Close, Delete, Loop } from '@mui/icons-material';
import loaderGif from "images/GIF-Loader-3.gif";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Stripe from "images/stripe.png"

// components
import './packages.scss'
import Footer from 'components/footer/footer'
import AlertMessage from 'components/message/message'
import Usersidebar from 'components/user-sidebar/user-sidebar';
import useAlertMessage from 'Hooks/useAlertMessage';
import Header from 'components/header/header';
import { useUserAPICollection } from 'Hooks/useUserAPICollection';
import { changeAmountformat, changeDollarAmountformat } from 'utils/changeDollarFormat';
import { decodeBase64ToParams, encodeParamsToBase64 } from 'utils/base64';
import ConfirmationModal from 'utils/confirmationModal';
import { ContainerLoader } from 'components/common/ContainerLoader';
import CountdownRealTime from 'utils/countdownRealTime';
import { LinearProgressBar } from 'utils/linearProgressBar';
import Cookies from 'js-cookie';


const purchasebtnmodalStyle = {
    minWidth: "8rem!important",
    backgroundColor: "#001D7D!important",
    color: "white!important",
    fontFamily: "Barlow!important",
    textTransform: "none!important",
    fontSize: "1.1rem",
    fontWeight: "bold",
};

const purchasebtnmodalStylePurchaeCredit = {
    maxWidth: "13rem!important",
    backgroundColor: "#001D7D!important",
    color: "white!important",
    fontFamily: "Barlow!important",
    textTransform: "none!important",
    fontSize: "1.1rem",
    width: "100%",
    marginTop: '11px',
    fontWeight: "bold",
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const steps = ["Package Details", " Choose Backup Payment Method", "Order Confirmation"];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    marginTop: "10px",
    color: theme.palette.text.secondary,
}));

const SwitchCustom = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const Packages = () => {
    const history = useHistory();
    const urlParams = new URLSearchParams(window?.location?.search);
    const {
        getAllPackageAPI,
        getSripSavedCardsAPi,
        addStripeCustPayMethodApi,
        deleteStripePaymentMethodApi,
        purchasePackageAPI,
        unsubscribePackageAPI,
        resubscribePackageAPI,
        getAvailableCredit,
        changePlanAPI
    } = useUserAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [packageList, setPackageList] = useState([]);
    const [isTermsCondition, setIsTermsCondition] = useState(false);
    const [userAvailableCredit, setUserAvailableCredit] = useState(0);
    const [isLoadingSavedCard, setIsLoadingSavedCard] = useState({
        isLoadingSavedCard: true,
        isDisabledRefreshBtn: false,
        isPaymentFailed: false,
        isContentLoading: false,
    });
    const [selectedCard, setSelectedCard] = useState(null);
    const [stripeCardList, setStripeCardList] = useState([]);
    const [cardId, setCardId] = useState("");
    const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
    const [queryStringRemovedByUser, setQueryStringRemovedByUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        package: null,
        payment_method_id: null
    });
    const [activePackage, setActivePackage] = useState({});
    const [unsubscribeState, setUnsubscribeState] = useState({
        isUnsubscribeModalOpen: false,
        packageId: null,
    });
    const [resubscribeState, setResubscribeState] = useState({
        isResubscribeModalOpen: false,
        packageId: null,
    });
    const [changePlan, setChangePlan] = useState({
        isChangePlanModalOpen: false,
        packageId: null,
    });
    const [isVisibleModelStates, setIsVisibleModelStates] = useState({
        isVisiblePurchaseLicenseModal: false,
        isDisabledPurchaseLicenseButton: false,
    });
    const [isUpdateStateValue, setIsUpdateStateValue] = useState({
        isBack: false,
    });
    const [activeStep, setActiveStep] = React.useState(0);


    const handleVisiablePurchasePackageModal = (item) => {
        setIsVisibleModelStates({
            ...isVisibleModelStates,
            isVisiblePurchaseLicenseModal: true,
        });
        setFormData({
            ...formData,
            package: item,
        })
    }

    const totalSteps = () => {
        return steps.length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep()
                ? steps.findIndex((step, i) => !(i))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        if (isUpdateStateValue.isBack === false) {
            handleSetStatePurchasePackage()
        }
        setIsUpdateStateValue({
            ...isUpdateStateValue,
            isBack: true
        })
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (isUpdateStateValue.isBack === false) {
            handleSetStatePurchasePackage()
        }
        setIsUpdateStateValue({
            ...isUpdateStateValue,
            isBack: true
        })
        setIsTermsCondition(false);
    };

    const handleSetStatePurchasePackage = () => {
        const encodedParams = urlParams?.get("p");
        if (encodedParams) {
            const decodedParams = decodeBase64ToParams(encodedParams);
            if (decodedParams) {
                setFormData({
                    ...formData,
                    package: decodedParams?.packageId ?? null,
                })
            }
        }
    };

    const handleclosePurchasePackageModal = () => {
        setIsTermsCondition(false)
        setSelectedCard(null);
        setActiveStep(0)
        setFormData({
            ...formData,
            payment_method_id: null,
            package: null,
        })
        setIsVisibleModelStates({
            ...isVisibleModelStates,
            isVisiblePurchaseLicenseModal: false,
        });
        history.replace(window.location.pathname);
    }

    const handleCardClick = (id) => {
        setSelectedCard(id);
        setFormData({
            ...formData,
            payment_method_id: id === "other" ? null : id
        })
    };

    const handleAgreeTermsConditon = (e) => {
        setIsTermsCondition(e.target.checked)
    }

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const handleVisableDeleteConfimationModal = (id) => {
        setIsDeleteModalShow(true)
        setCardId(id)
    }

    const handleCloseDeleteModal = (id) => {
        setIsDeleteModalShow(false)
        setCardId("")
    }

    const handleOpenConfimUnsubscribe = (id) => {
        if (id) {
            setUnsubscribeState({
                ...unsubscribeState,
                isUnsubscribeModalOpen: true,
                packageId: id
            })
        }
    }

    const handleCloseUnsubscribeModal = () => {
        setUnsubscribeState({
            isUnsubscribeModalOpen: false,
            packageId: null,
        })
    }

    const handleOpenConfimResubscribeModal = (id) => {
        setResubscribeState({
            ...resubscribeState,
            isResubscribeModalOpen: true,
            packageId: id
        })
    }

    const handleChangePlanModal = (id) => {
        if (id) {
            setChangePlan({
                ...changePlan,
                isChangePlanModalOpen: true,
                packageId: id,
            });
        }
    }

    const handleCloseResubscribeModal = () => {
        setResubscribeState({
            ...resubscribeState,
            isResubscribeModalOpen: false,
            packageId: null,
        })
        setChangePlan({
            ...changePlan,
            isChangePlanModalOpen: false,
            packageId: null,
        });
    }

    const handleResubscribePackage = async () => {
        try {
            const res = await resubscribePackageAPI({ _id: resubscribeState?.packageId });
            const { data } = res;
            if (data.error) {
                errorMessage(data.message, "error");
            } else {
                errorMessage(data.message, "success");
                getAllPackage();
                handleCloseResubscribeModal();
            }
        } catch (error) {
            errorMessage();
        }
    }

    const handleUnsubscribePackage = async () => {
        try {
            const res = await unsubscribePackageAPI({ _id: unsubscribeState?.packageId });
            const { data } = res;
            if (data.error) {
                errorMessage(data.message, "error");
            } else {
                errorMessage(data.message, "success");
                getAllPackage();
                handleCloseUnsubscribeModal();
            }
        } catch (error) {
            errorMessage();
        }
    }

    const handlePurchasePackage = async () => {
        try {
            setIsVisibleModelStates({
                ...isVisibleModelStates,
                isDisabledPurchaseLicenseButton: true,
            });
            const res = await purchasePackageAPI({
                packageId: formData?.package?._id,
                payment_method_id: formData?.payment_method_id,
            });
            const { data } = res;
            if (data.error) {
                handleclosePurchasePackageModal()
                errorMessage(data.message, "error")
            } else {
                handleclosePurchasePackageModal()
                errorMessage(data.message, "success")

                getAllPackage()
            }
        } catch (error) {
            handleclosePurchasePackageModal()
            errorMessage();
        }
    }

    const getAllPackage = async () => {
        try {
            setIsLoading(true);
            const res = await getAllPackageAPI();
            const { data } = res;
            if (data.tokenExpired && data.error) {
                errorMessage(data?.message, "error");
                Cookies.remove('at')
                Cookies.remove('accessToken')
                history.push("/login")
            } else if (data.error) {
                errorMessage(data.message, "error")
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setPackageList(data?.data ?? []);
                setActivePackage(data?.userpackage ?? {});
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false);
        }
    }

    const getSavedStripeList = async () => {
        try {
            setIsLoadingSavedCard({
                ...isLoadingSavedCard,
                isLoadingSavedCard: true,
                isDisabledRefreshBtn: true
            });
            const response = await getSripSavedCardsAPi();
            const { data } = response;
            if (data.error) {
                errorMessage()
                setIsLoadingSavedCard({
                    ...isLoadingSavedCard,
                    isLoadingSavedCard: false,
                    isDisabledRefreshBtn: false
                });
            } else {
                setStripeCardList(data?.payment_methods)
                setIsLoadingSavedCard({
                    ...isLoadingSavedCard,
                    isLoadingSavedCard: false,
                    isDisabledRefreshBtn: false
                });
            }
        } catch (error) {
            setIsLoadingSavedCard({
                ...isLoadingSavedCard,
                isLoadingSavedCard: false,
                isDisabledRefreshBtn: false
            });
            errorMessage()
        }
    };

    const handleAddStripeCustomPayMethod = async () => {
        const url = window.location.href;
        const params = {
            packageId: formData?.package,
            paymentmethod: formData?.payment_method_id,
            activeStep: activeStep,
            isModal: true
        };
        const encodedParams = encodeParamsToBase64(params);
        if (encodedParams) {
            try {
                setIsVisibleModelStates({
                    ...isVisibleModelStates,
                    isDisabledPurchaseLicenseButton: true,
                });
                const response = await addStripeCustPayMethodApi({ success_url: `${url}?p=${encodedParams}` })
                const { data } = response;
                if (data.error) {
                    setIsVisibleModelStates({
                        ...isVisibleModelStates,
                        isDisabledPurchaseLicenseButton: false,
                    });
                    errorMessage()
                } else {
                    window.location.href = data?.checkout_url;
                    setIsVisibleModelStates({
                        ...isVisibleModelStates,
                        isDisabledPurchaseLicenseButton: false,
                    });
                }
            } catch (error) {
                errorMessage()
                setIsVisibleModelStates({
                    ...isVisibleModelStates,
                    isDisabledPurchaseLicenseButton: false,
                });
            }
        } else {
            errorMessage()
            setIsVisibleModelStates({
                ...isVisibleModelStates,
                isDisabledPurchaseLicenseButton: false,
            });
        }
    }

    const handleDeleteStripePaymentMethod = async () => {
        try {
            setIsDeleteModalShow(false)
            const response = await deleteStripePaymentMethodApi({ payment_method_id: cardId })
            const { data } = response;
            if (data.error) {
                errorMessage()
            } else {
                errorMessage(data.message, "success")
                await getSavedStripeList()
            }
        } catch (error) {
            errorMessage()
        }
    }

    const getUserAvailableCredit = async () => {
        try {
            const response = await getAvailableCredit();
            const { data } = response;
            if (data.error) {
                errorMessage();
            } else {
                setUserAvailableCredit(parseFloat(data?.data) ?? 0)
            }
        } catch (error) {
            errorMessage();
        }
    };

    const handleChangePlan = async () => {
        try {
            setIsLoading(true);
            const res = await changePlanAPI({ packageId: changePlan?.packageId });
            const { data } = res;
            if (data.error) {
                errorMessage(data.message, "error")
                setIsLoading(false);
            } else {
                setIsLoading(false);
                errorMessage(data.message, "success")
                getAllPackage();
                setChangePlan({
                    ...changePlan,
                    isChangePlanModalOpen: false,
                    packageId: null,
                });
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false);
        }

    }

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (action === 'POP' && !queryStringRemovedByUser) {
                history.replace(location?.pathname);
            }
            setQueryStringRemovedByUser(false);
        });
        return () => {
            unlisten();
        };
    }, [history, queryStringRemovedByUser])

    useEffect(() => {
        const encodedParams = urlParams.get("p")
        const decodedParams = decodeBase64ToParams(encodedParams);
        if (decodedParams) {
            setIsVisibleModelStates({
                ...isVisibleModelStates,
                isVisiblePurchaseLicenseModal: true,
            });
            setFormData({
                ...formData,
                package: decodedParams?.packageId ?? null,
            })
            setActiveStep((decodedParams && decodedParams?.activeStep))
        }
        getUserAvailableCredit();
        getAllPackage();
        getSavedStripeList();
    }, []);


    return (
        <>
            <div className="user-dashboard-wrapper">
                <Helmet>
                    <title>Packages</title>
                </Helmet>
                <Header />
                <div className="c-app c-default-layout user-dashboard-body">
                    <Usersidebar />
                    <div className="c-wrapper">
                        <div className="c-body">
                            <main className="c-main">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className="space-top-col">
                                        <Card
                                            sx={{
                                                width: "100%",
                                                maxWidth: "100%",
                                                color: "#000059!important",
                                                textAlign: "left",
                                                padding: "20px 20px 20px 20px",
                                                backgroundColor: "#fbfbfb;",
                                            }}
                                        >
                                            <div className="vendortable-header">
                                                <Typography
                                                    variant="h6"
                                                    className='heading-package'
                                                    sx={{
                                                        width: "100%",
                                                        maxWidth: "14rem",
                                                        color: "#000059!important",
                                                        textAlign: "left",
                                                        fontWeight: "bold",
                                                        fontSize: "1.6rem",
                                                        fontFamily: "Barlow!important",
                                                        marginBottom: '79px',
                                                    }}
                                                >
                                                    {" "}
                                                    Packages
                                                </Typography>
                                                <Grid
                                                    container={isLoading ? false : true}
                                                    spacing={0}
                                                    className="button-align-purchase"
                                                    sx={{ justifyContent: "end" }}
                                                >
                                                    {!activePackage?.data ? (
                                                        <Grid item xs={12} sm={12} md={12} lg={5} sx={{ position: 'relative' }} className='available-credit'>
                                                            <Card className="card-custom-lic-pur">
                                                                <CardContent
                                                                    sx={{
                                                                        height: "107px",
                                                                        width: "207px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center"
                                                                    }}
                                                                    className="licnses-cardContent-purchase">
                                                                    {isLoading ? (
                                                                        <div className="account-have-img">
                                                                            <Grid container spacing={0}>
                                                                                <div
                                                                                    sx={{
                                                                                        padding: "0px 15px!important",
                                                                                        display: "flex",
                                                                                    }}
                                                                                >
                                                                                    <Box sx={{ display: 'flex' }}>
                                                                                        <CircularProgress />
                                                                                    </Box>
                                                                                </div>
                                                                            </Grid>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="account-have-img">
                                                                            <Grid container spacing={0}>
                                                                                <div
                                                                                    sx={{
                                                                                        padding: "0px 15px!important",
                                                                                        display: "flex",
                                                                                    }}
                                                                                >
                                                                                    <h2 style={{ lineHeight: "13px" }}>
                                                                                        No Package
                                                                                    </h2>
                                                                                </div>
                                                                            </Grid>
                                                                        </div>
                                                                    )}
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    ) : (
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={7} sx={{ position: 'relative' }} className='available-credit'>
                                                            <Card className="card-custom-lic-pur-package flex">
                                                                <Grid item xs={6} sm={12} md={12} lg={6} className="packages-card-main-div">
                                                                    <CardContent className="licnses-cardContent-purchase-package">
                                                                        <div className="account-have-img">
                                                                            <Grid container spacing={0}>
                                                                                <Grid
                                                                                    item
                                                                                    xs="12"
                                                                                    sm="12"
                                                                                    md="12"
                                                                                    lg="12"
                                                                                    sx={{
                                                                                        padding: "0px 15px!important",
                                                                                        display: "flex",
                                                                                        alignItems: "flex-end",
                                                                                        justifyContent: "flex-start",
                                                                                    }}
                                                                                >
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        spacing={2}
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent:
                                                                                                "space-between",
                                                                                            width: "100%",
                                                                                        }}
                                                                                    >
                                                                                        <Box>
                                                                                            <h2
                                                                                                style={{
                                                                                                    color: "#002583",
                                                                                                    fontWeight: "bold",
                                                                                                }}
                                                                                            >
                                                                                                Package Details
                                                                                            </h2>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Stack direction="row">
                                                                                                <Box>
                                                                                                    <h2>
                                                                                                        Auto-Renew:{" "}
                                                                                                    </h2>
                                                                                                </Box>
                                                                                                <Box>
                                                                                                    <h2>
                                                                                                        {activePackage?.data
                                                                                                            ?.auto_renewal ===
                                                                                                            "on"
                                                                                                            ? "ON"
                                                                                                            : "OFF"}
                                                                                                    </h2>
                                                                                                </Box>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                    </Stack>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs="12"
                                                                                    sm="12"
                                                                                    md="12"
                                                                                    lg="12"
                                                                                    sx={{
                                                                                        padding: "0px 15px!important",
                                                                                        display: "flex",
                                                                                        alignItems: "flex-end",
                                                                                        justifyContent: "flex-start",

                                                                                    }}
                                                                                >
                                                                                    <h2 style={{ lineHeight: "13px" }}>
                                                                                        Name:{" "}
                                                                                        {activePackage?.data?.packageId
                                                                                            ?.name ?? "No Package"}
                                                                                    </h2>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs="12"
                                                                                    sm="12"
                                                                                    md="12"
                                                                                    lg="12"
                                                                                    sx={{
                                                                                        padding: "0px 15px!important",
                                                                                        display: "flex",
                                                                                        alignItems: "flex-end",
                                                                                        justifyContent: "flex-start",
                                                                                    }}
                                                                                >
                                                                                    <h2 style={{ lineHeight: "13px" }}>
                                                                                        Time Left:{" "}
                                                                                        {activePackage?.data
                                                                                            ?.validUpto ? (
                                                                                            <CountdownRealTime
                                                                                                targetDate={
                                                                                                    activePackage?.data
                                                                                                        ?.validUpto
                                                                                                }
                                                                                                timeZone="America/New_York"
                                                                                            />
                                                                                        ) : null}
                                                                                    </h2>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Stack
                                                                                direction="column"
                                                                                xs="12"
                                                                                sm="12"
                                                                                md="12"
                                                                                lg="12"
                                                                                className="icon_size"
                                                                                sx={{
                                                                                    width: "100% !important",
                                                                                    display: "flex",
                                                                                    alignItems: "baseline",
                                                                                    padding: "0px 15px!important",
                                                                                    justifyContent: {
                                                                                        xs: "center",
                                                                                        sm: "center",
                                                                                        md: "flex-start",
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <Box>
                                                                                    <h2 style={{ lineHeight: "13px" }}>
                                                                                        Tickets Delivered:
                                                                                    </h2>
                                                                                </Box>
                                                                                <LinearProgressBar
                                                                                    data={activePackage?.data?.tickets_delivered}
                                                                                />
                                                                            </Stack>
                                                                        </div>
                                                                    </CardContent>
                                                                </Grid>
                                                                <Grid item xs={6} sm={12} md={12} lg={6} className="packages-card-main-div">
                                                                    <CardContent className="licnses-cardContent-purchase-package" >
                                                                        <div className="account-have-img">
                                                                            {activePackage?.data?.cardDetail?.holderEmail ? (
                                                                                <Grid container spacing={0}>
                                                                                    <Grid
                                                                                        item
                                                                                        xs="12"
                                                                                        sm="12"
                                                                                        md="12"
                                                                                        lg="12"
                                                                                        sx={{
                                                                                            padding: "0px 15px!important",
                                                                                            display: "flex",
                                                                                            alignItems: "flex-end",
                                                                                            justifyContent: "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <Stack
                                                                                            direction="row"
                                                                                            spacing={2}
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                justifyContent:
                                                                                                    "space-between",
                                                                                                width: "100%",
                                                                                            }}
                                                                                        >
                                                                                            <Box>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        color: "#002583",
                                                                                                        fontWeight: "bold",
                                                                                                    }}
                                                                                                >
                                                                                                    Card Details
                                                                                                </h2>
                                                                                            </Box>
                                                                                        </Stack>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs="12"
                                                                                        sm="12"
                                                                                        md="12"
                                                                                        lg="12"
                                                                                        sx={{
                                                                                            padding: "0px 15px!important",
                                                                                            display: "flex",
                                                                                            alignItems: "flex-end",
                                                                                            justifyContent: "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <h2 style={{ lineHeight: "13px" }}>
                                                                                            Card Holder:{" "}
                                                                                            {activePackage?.data?.cardDetail
                                                                                                ?.holderEmail ?? "No Email available"}
                                                                                        </h2>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs="12"
                                                                                        sm="12"
                                                                                        md="12"
                                                                                        lg="12"
                                                                                        sx={{
                                                                                            padding: "0px 15px!important",
                                                                                            display: "flex",
                                                                                            alignItems: "flex-end",
                                                                                            justifyContent: "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <h2 style={{ lineHeight: "13px" }}>
                                                                                            Card Name:{" "}
                                                                                            {(activePackage?.data?.cardDetail?.cardBrand?.toUpperCase()) ?? "No Name available"}
                                                                                        </h2>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs="12"
                                                                                        sm="12"
                                                                                        md="12"
                                                                                        lg="12"
                                                                                        sx={{
                                                                                            padding: "0px 15px!important",
                                                                                            display: "flex",
                                                                                            alignItems: "flex-end",
                                                                                            justifyContent: "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <h2 style={{ lineHeight: "13px" }}>
                                                                                            Card Number:{" "}
                                                                                            {activePackage?.data?.cardDetail ? `XXXX XXXX XXXX ${activePackage?.data?.cardDetail
                                                                                                ?.cardLastDigits ?? null}` : "No Card Number available"}
                                                                                        </h2>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs="12"
                                                                                        sm="12"
                                                                                        md="12"
                                                                                        lg="12"
                                                                                        sx={{
                                                                                            padding: "0px 15px!important",
                                                                                            display: "flex",
                                                                                            alignItems: "flex-end",
                                                                                            justifyContent: "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <h2 style={{ lineHeight: "13px" }}>
                                                                                            Expire Date:{" "}
                                                                                            {activePackage?.data?.cardDetail
                                                                                                ?.expireDate ?? "No ExpireDate available"}
                                                                                        </h2>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ) : (
                                                                                <Grid container spacing={0}>
                                                                                    <Grid
                                                                                        item
                                                                                        xs="12"
                                                                                        sm="12"
                                                                                        md="12"
                                                                                        lg="6"
                                                                                        sx={{
                                                                                            padding: "0px 15px!important",
                                                                                            display: "flex",
                                                                                            alignItems: "flex-end",
                                                                                            justifyContent: "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <Stack
                                                                                            direction="row"
                                                                                            spacing={2}
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                justifyContent:
                                                                                                    "space-between",
                                                                                                width: "100%",
                                                                                            }}
                                                                                        >
                                                                                            <Box>
                                                                                                <h2
                                                                                                    style={{
                                                                                                        color: "#002583",
                                                                                                        fontWeight: "bold",
                                                                                                    }}
                                                                                                >
                                                                                                    Card Details
                                                                                                </h2>
                                                                                            </Box>
                                                                                        </Stack>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs="12"
                                                                                        sm="12"
                                                                                        md="12"
                                                                                        lg="12"
                                                                                        sx={{
                                                                                            padding: "0px 15px!important",
                                                                                            display: "flex",
                                                                                            alignItems: "flex-end",
                                                                                            justifyContent: "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <h2 style={{ lineHeight: "13px" }}>
                                                                                            No Card available
                                                                                        </h2>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        </div>
                                                                    </CardContent>
                                                                </Grid>
                                                            </Card>
                                                        </Grid>)}
                                                    <Grid item xs={12} sm={12} md={12} lg={isLoading ? 6 : 12} xl={isLoading ? 0 : 2.9}
                                                        sx={{ position: 'relative' }}
                                                        className='available-credit'
                                                    >
                                                        <Card className="card-custom-lic-pur">
                                                            <CardContent className="licnses-cardContent-purchase">
                                                                <div className="account-have-img-license-purchase">
                                                                    <Grid container spacing={0} className='container-available-credit'>
                                                                        <Grid item xs={6} sm={6} md={6} lg={2}
                                                                            sx={{
                                                                                padding: "0px 15px!important",
                                                                                display: "flex",
                                                                                alignItems: "flex-end",
                                                                                justifyContent: "flex-start",
                                                                                maxWidth: "100% !important"
                                                                            }}
                                                                        >
                                                                            {isLoading ? (
                                                                                <div className={"plan-prices-lic-package card-textAlign"}>
                                                                                    <Box sx={{
                                                                                        display: 'flex',
                                                                                        justifyContent: "center",
                                                                                        paddingTop: "21px",
                                                                                        paddingBottom: "4px",
                                                                                    }}>
                                                                                        <CircularProgress />
                                                                                    </Box>
                                                                                </div>
                                                                            ) : (
                                                                                <div className={activePackage?.data ? "plan-prices-lic-package card-textAlign" : "plan-prices-lic-pur-package card-textAlign"}>
                                                                                    <h2 style={{
                                                                                        width: '100%',
                                                                                        minWidth: "max-content",
                                                                                        color: '#000057 !important',
                                                                                        marginBottom: "0px",
                                                                                        marginTop: "14px"
                                                                                    }}
                                                                                    >
                                                                                        Available Credit
                                                                                    </h2>
                                                                                    <span style={{ color: userAvailableCredit < 0 ? "red" : "#00855D" }} >{changeDollarAmountformat(userAvailableCredit?.toFixed(2)) ?? "$00.0"}</span>
                                                                                </div>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <Grid container spacing={3}>
                                                {isLoading ? (
                                                    <Grid item xs={12}>
                                                        <ContainerLoader />
                                                    </Grid>
                                                ) : (
                                                    packageList?.length > 0 ? (
                                                        packageList.map((item, index) => (
                                                            <Grid key={index} item xs={12} sm={6} md={6} lg={4} className='mt-3 p-0' style={{ display: "flex", justifyContent: "center" }}>
                                                                <CardContent
                                                                    sx={{
                                                                        display: "flex",
                                                                        padding: "0px!important",
                                                                        marginTop: "30px!important",
                                                                        width: "80%"
                                                                    }}
                                                                >
                                                                    <div className="pricing-table" style={{ width: "100%" }}>
                                                                        <div className="pricing-card" style={{ width: "100%" }}>
                                                                            <div className="card-header basic" style={{ backgroundColor: item?.color ? item?.color : "#EBEEF5" }}>
                                                                                <h2 className='py-2 px-3' style={{ borderBottom: "1px solid  #7c7677", color: "black" }}>{item?.name}</h2>
                                                                                <h3 className='py-2'>{changeDollarAmountformat(item?.price)}</h3>
                                                                            </div>
                                                                            <p style={{ fontSize: "12px" }} className={`${activePackage?.packageId === item._id ? "monthly per-month" : "monthly"}`}>PER MONTH</p>
                                                                            {activePackage?.packageId === item._id ? (
                                                                                <p className="active mb-0">
                                                                                    <RadioButtonCheckedIcon sx={{ color: "#53CD00", fontSize: "19px" }} /><b>ACTIVE</b>
                                                                                </p>
                                                                            ) : (
                                                                                <p className="description py-2 mb-0" />
                                                                            )}
                                                                            <p className="description py-2 mb-0">
                                                                                <b>{changeAmountformat(item?.tickets_count)} TICKETS INCLUDED</b>
                                                                            </p>
                                                                            <p className="description py-2 mb-0">
                                                                                <b>{changeDollarAmountformat(item?.additional_ticket_price)} PER ADDITIONAL TICKET</b>
                                                                            </p>
                                                                            {activePackage?.packageId === item._id &&
                                                                                <p className="description-auto">
                                                                                    <b>Auto-Renewal:</b> {activePackage?.data?.auto_renewal ?
                                                                                        (activePackage?.data.auto_renewal === "on") ? (
                                                                                            <SwitchCustom
                                                                                                onClick={() => handleOpenConfimUnsubscribe(activePackage?.data._id)}
                                                                                                checked={activePackage?.data?.auto_renewal === "on" && true}
                                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                            />
                                                                                        ) : (activePackage?.data?.auto_renewal === "off") ? (
                                                                                            <SwitchCustom
                                                                                                onClick={() => handleOpenConfimResubscribeModal(activePackage?.data._id)}
                                                                                                checked={activePackage?.data?.auto_renewal === "off" && false}
                                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                                            />
                                                                                        ) : ("-") : ("-")}
                                                                                </p>
                                                                            }

                                                                            <div className='button-packages'>
                                                                                {activePackage?.packageId && activePackage?.upcomingPackageId ?
                                                                                    activePackage?.packageId === item._id ? (
                                                                                        <button
                                                                                            onClick={() => handleChangePlanModal(item?._id)}
                                                                                            disabled={
                                                                                                activePackage?.packageId === activePackage?.upcomingPackageId ? true :
                                                                                                    activePackage?.packageId !== activePackage?.upcomingPackageId ? false :
                                                                                                        true
                                                                                            }
                                                                                            className="btn basic-btn mt-2"
                                                                                        >
                                                                                            Selected
                                                                                        </button>
                                                                                    ) : (activePackage?.upcomingPackageId === item._id) ?
                                                                                        (
                                                                                            <button
                                                                                                disabled={Object.keys(activePackage).length > 0 ? true : false}
                                                                                                className="btn basic-btn mt-2"
                                                                                            >
                                                                                                Upcoming Package
                                                                                            </button>
                                                                                        ) : (
                                                                                            <button
                                                                                                onClick={() => handleChangePlanModal(item?._id)}
                                                                                                className="btn basic-btn mt-2"
                                                                                            >
                                                                                                Change Package
                                                                                            </button>
                                                                                        ) : (
                                                                                        <button
                                                                                            onClick={() => handleVisiablePurchasePackageModal(item)}
                                                                                            className="btn basic-btn mt-2"
                                                                                        >
                                                                                            Choose Package
                                                                                        </button>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardContent>

                                                            </Grid>
                                                        ))
                                                    ) : (
                                                        <Grid item xs={12}>
                                                            Package Not Found
                                                        </Grid>
                                                    )
                                                )}
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </main>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
            {/* purchase Packages modal */}
            <Dialog
                className="purchase-license-modal"
                onClick={handleBackdropClick}
                fullWidth={true}
                maxWidth="md"
                onClose={handleclosePurchasePackageModal}
                aria-labelledby="customized-dialog-title"
                open={isVisibleModelStates?.isVisiblePurchaseLicenseModal}
                TransitionComponent={Transition}
            >
                <div className="modal-purachse-licenses-scrollDiv">
                    <DialogTitle id="customized-dialog-title"
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: "17px 0px"
                        }}>
                        <Box sx={{ width: "100%" }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps?.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        className="modal-icon-button"
                        onClick={handleclosePurchasePackageModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                            p: 1,
                        }}
                    >
                        <Close />
                    </IconButton>
                    <DialogContent>
                        {(activeStep === 0) ? (
                            <React.Fragment>
                                {isLoadingSavedCard?.isLoadingSavedCard ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "11rem 0",
                                        }}
                                    >
                                        <div style={{ position: 'relative', top: '4px' }}>
                                            <img className="loader-size-table" src={loaderGif} alt="no loader" />
                                        </div>
                                    </Box>
                                ) : (
                                    <>
                                        <DialogTitle id="customized-dialog-title" sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: "7px 14px"
                                        }}>
                                            Package Details
                                            <Typography
                                                variant="h5"
                                                className="purchase-license-typography"
                                            >
                                                <span className="Account_details_box-lic" >Available Credit :</span>
                                                <span style={{ fontWeight: "600", color: userAvailableCredit < 0 ? "red" : "#00855D" }}>
                                                    {' '}{changeDollarAmountformat(userAvailableCredit) ?? "$0.00"}
                                                </span>
                                            </Typography>
                                        </DialogTitle>
                                        <Typography className="product-text-btn" sx={{ display: "flex" }}>
                                            <span className="btn-span">
                                                <>
                                                    {userAvailableCredit >= 0 ?
                                                        ("")
                                                        :
                                                        (<>
                                                                <div style={{
                                                                    display: "flex",
                                                                    justifyContent: "end",
                                                                    marginRight: "15px"
                                                                }}>
                                                                    <Button
                                                                        component={Link}
                                                                        className=''
                                                                        to='/purchase-credit'
                                                                        variant="contained"
                                                                        type="submit"
                                                                        size="small"
                                                                        sx={purchasebtnmodalStylePurchaeCredit}
                                                                        onClick={() => localStorage?.setItem('licensePageUrl', 'true')}
                                                                    >
                                                                        Purchase Credit
                                                                    </Button>
                                                                </div>
                                                        </>)
                                                    }
                                                </>
                                            </span>
                                        </Typography>
                                        <DialogContent>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="purtablecell">Package</TableCell>
                                                            <TableCell className="purtablecell" ></TableCell>
                                                            <TableCell className="purtablecell" ></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell scope="row">
                                                                {(formData && formData?.package) && formData?.package?.name}
                                                            </TableCell>
                                                            <TableCell >
                                                                <b>{changeDollarAmountformat(formData?.package?.price.toFixed(2)) ?? "0.00"}/month</b>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell scope="row">
                                                                Tickets Included
                                                            </TableCell>
                                                            <TableCell scope="row">
                                                                <b>{changeAmountformat(formData?.package?.tickets_count) ?? 0}</b>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableCell scope="row">
                                                            Price Per Additional Ticket
                                                        </TableCell>
                                                        <TableCell scope="row">
                                                            <b>{changeDollarAmountformat(formData?.package?.additional_ticket_price.toFixed(2)) ?? "$0.00"}</b>
                                                        </TableCell>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        />
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Typography sx={{ paddingTop: '14px' }}>
                                                <span>
                                                    Package duration {" "}:{" "} 30 Days
                                                </span>
                                                <span style={{ paddingLeft: "30px", borderRight: "1px solid gray" }}></span>
                                                <span style={{ paddingLeft: "30px" }}>
                                                    Auto-Renewal {" "}:{" "} On
                                                </span>
                                            </Typography>
                                            <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                                <span className="purchase-license-modal-heading">
                                                    {userAvailableCredit >= 0 ?
                                                        ("")
                                                        :
                                                        (
                                                            <span style={{ color: "red" }}>
                                                                You don't have enough funds. Please purchase credit before purchasing the package.
                                                            </span>
                                                        )
                                                    }
                                                </span>
                                            </span>
                                        </DialogContent>
                                    </>)}
                            </React.Fragment>
                        ) : (activeStep === 1) ? (
                            <React.Fragment>
                                <DialogTitle id="customized-dialog-title"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: "6px 12px"
                                    }}>
                                    Choose Backup Payment Method
                                    <img style={{
                                        width: "123px",
                                        height: "30px",
                                        marginRight: "190px",
                                        marginTop: "4px",
                                    }}
                                        src={Stripe}
                                        alt="stripe"
                                    />
                                    {stripeCardList && stripeCardList?.length > 0 ? (
                                        <Typography
                                            variant="h5"
                                        >
                                            <span className="Account_details_box-lic" >
                                                <LoadingButton
                                                    onClick={() => getSavedStripeList()}
                                                    size="small"
                                                    loading={isLoadingSavedCard?.isLoadingSavedCard}
                                                    endIcon={isLoadingSavedCard?.isLoadingSavedCard ? (<CircularProgress color="inherit" size={20} />) : (<Loop />)}
                                                    disabled={isLoadingSavedCard?.isLoadingSavedCard}
                                                    loadingPosition="end"
                                                    variant="contained"
                                                >
                                                    Refresh
                                                </LoadingButton>
                                            </span>
                                        </Typography>
                                    ) : (null)}
                                </DialogTitle>
                                <DialogContent>
                                    <div>
                                        <FormControl className="saved-card-details">
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={selectedCard}
                                                name="radio-buttons-group"
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Item>
                                                            <span className="Account_details_box-lic purchase-license-modal-texttwo heading-modal-choose-payment">
                                                                <span className="purchase-lic-modal-heading2"
                                                                    style={{
                                                                        fontSize: "15px",
                                                                        padding: "0px 0px 9px 0px"
                                                                    }}>
                                                                    Saved Cards
                                                                </span>
                                                            </span>
                                                            <div class="inner-wrapper-purchase-license">
                                                                {!isLoadingSavedCard?.isLoadingSavedCard ? (
                                                                    <>
                                                                        {stripeCardList && stripeCardList?.length > 0 ? (
                                                                            <>
                                                                                {stripeCardList && stripeCardList?.map((item, index) => (
                                                                                    <div className="credit-card-inner">
                                                                                        <div className={`credit-card ${selectedCard === item.id ? 'selected' : ''}`} onClick={() => handleCardClick(item.id)}>
                                                                                            <FormControlLabel
                                                                                                key={item.id}
                                                                                                value={item.id}
                                                                                                control={<Radio sx={{ marginTop: "6px" }} />}
                                                                                                sx={{ width: '100%' }}
                                                                                                label={
                                                                                                    <Typography variant="body5" color="textSecondary" sx={{ width: "100%" }}>
                                                                                                        <div className="content">
                                                                                                            <div className="content-card-name">
                                                                                                                <div>Card Holder : {item?.name ?? null}</div>
                                                                                                                <div>{(item?.card && item?.card?.brand?.toUpperCase()) ?? null}</div>
                                                                                                            </div>
                                                                                                            <div className="content-card-detail">
                                                                                                                <span className="card-number">XXXX XXXX XXXX {(item?.card && item?.card?.last4) ?? null}</span>
                                                                                                                <span>Expiry Date : {(item?.card && item?.card?.exp_month) ?? null}/{(item?.card && item?.card?.exp_year) ?? null}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Typography>
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        &nbsp;&nbsp;
                                                                                        <div className="credit-card-delete">
                                                                                            <Stack direction="row" spacing={1}>
                                                                                                <Tooltip title="Delete">
                                                                                                    <IconButton aria-label="delete" onClick={() => handleVisableDeleteConfimationModal(item.id)}>
                                                                                                        <Delete />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </Stack>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </>
                                                                        ) : (
                                                                            <div>
                                                                                <td
                                                                                    colSpan={13}
                                                                                    style={{ padding: "0.6rem 17rem 25px" }}
                                                                                >
                                                                                    <Typography
                                                                                        variant="h6"
                                                                                        sx={{
                                                                                            fontSize: "1.6rem",
                                                                                            textAlign: "center",
                                                                                        }}
                                                                                    >
                                                                                        No Card Found
                                                                                    </Typography>
                                                                                </td>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            padding: "8rem 0",
                                                                        }}
                                                                    >
                                                                        <div style={{ position: 'relative', top: '4px' }}>
                                                                            <img className="loader-size-table" src={loaderGif} alt="no loader" />
                                                                        </div>
                                                                    </Box>
                                                                )}
                                                            </div>
                                                        </Item>
                                                        {stripeCardList && stripeCardList?.length > 0 ? (
                                                            <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                                                <span className="purchase-lic-modal-heading2"
                                                                    style={{
                                                                        fontSize: "15px",
                                                                        color: "red !important"
                                                                    }}>
                                                                    Note: Please hit refresh above to get the latest saved cards
                                                                </span>
                                                            </span>
                                                        ) : (null)}
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ paddingTop: "0px!important", paddingBottom: "10px !important" }}>
                                                        <Item>
                                                            <span className="Account_details_box-lic purchase-license-modal-texttwo heading-modal-choose-payment">
                                                                <span className="purchase-lic-modal-heading2"
                                                                    style={{
                                                                        fontSize: "15px",
                                                                        padding: "0px 0px 9px 0px"
                                                                    }}>
                                                                    Add Payment Method
                                                                </span>
                                                            </span>
                                                            <div className={`credit-card credit-card-other-option ${selectedCard === 'other' ? 'selected' : ''}`} onClick={() => handleCardClick('other')}>
                                                                <FormControlLabel
                                                                    value={selectedCard === "other" ? "other" : ""}
                                                                    control={<Radio sx={{ marginTop: "6px" }} />}
                                                                    sx={{ width: '100%' }}
                                                                    label={
                                                                        <Typography variant="body5" color="textSecondary" sx={{ width: "100%" }}>
                                                                            <div className="content">
                                                                                <div className="content-card-name">
                                                                                    <div className="other-text" >Other</div>
                                                                                </div>
                                                                            </div>
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </div>
                                                        </Item>
                                                    </Grid>
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </DialogContent>
                            </React.Fragment>
                        ) : (activeStep === 2) ? (
                            <React.Fragment>
                                <DialogTitle id="customized-dialog-title" sx={{ padding: '0px 0px 0px 12px' }}>
                                    Order Confirmation
                                    <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                        <span className="purchase-lic-modal-heading2"
                                            style={{
                                                fontSize: "15px",
                                                marginTop: "-8px",
                                                padding: "0px 0px 16px"
                                            }}>
                                            Note: All charges will be deducted first from Ticket Liberty Credit.
                                        </span>
                                    </span>
                                </DialogTitle>
                                <DialogContent>
                                    <TableContainer>
                                        <Table>
                                            <TableBody sx={{ marginBottom: 3 }}>
                                                <Typography sx={{ fontWeight: 700, fontSize: 21, marginBottom: 1 }}>Order Totals</Typography>
                                                <TableRow sx={{ borderTop: "1px solid #E0E0E0" }} >
                                                    <TableCell sx={{ width: "40%" }} component="th" scope="row">
                                                        Total Due Now
                                                    </TableCell>
                                                    <TableCell  >
                                                        <b>{changeDollarAmountformat((formData?.package?.price)?.toFixed(2))}</b>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                            <br />
                                            <TableBody>
                                                <Typography sx={{ fontWeight: 700, fontSize: 21, marginBottom: 1 }}>Recurring Totals</Typography>
                                                <TableRow sx={{ borderTop: "1px solid #E0E0E0" }}>
                                                    <TableCell sx={{ width: "40%" }} component="th" scope="row">
                                                        Recurring Total
                                                    </TableCell>
                                                    <TableCell >
                                                        <b>{changeDollarAmountformat((formData?.package?.price)?.toFixed(2))}</b>{" "}/{" "}month<br />
                                                        <span>First automatic renewal will be charged 30 days after activation.<br /></span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => handleAgreeTermsConditon(e)}
                                                        checked={isTermsCondition}
                                                    />
                                                }
                                                label={
                                                    <span style={{ fontWeight: "bold", color: "#321fdb" }}>
                                                        I agree to the{" "}
                                                        <Link
                                                            style={{ fontWeight: "bold", textDecoration: "none" }}
                                                            passHref
                                                            target="_blank"
                                                            to="/term-and-condition"
                                                        >
                                                            <span style={{ textDecoration: "underline" }}>
                                                                Terms and Conditions.
                                                            </span>
                                                        </Link>
                                                    </span>
                                                }
                                            />
                                        </FormGroup>
                                        <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                            <span className="purchase-license-modal-heading">
                                                Note: Auto-renewal has been defaulted on.Turn off Auto-renewal to unsubscribe. If your Ticket Liberty credit is below the recurring charge, we will charge your credit card in full.
                                            </span>
                                        </span>
                                    </TableContainer>
                                </DialogContent>
                            </React.Fragment>
                        ) : (null)}
                    </DialogContent>
                    <DialogActions sx={{ padding: "0px 16px 10px 0px !important" }}>
                        <Box sx={{ display: "flex", flexDirection: "row" ,  padding:"22px" }}>
                            {(activeStep === 0) ? (
                                <Button
                                    onClick={handleclosePurchasePackageModal}
                                    variant="contained"
                                    type="button"
                                    size="small"
                                    sx={purchasebtnmodalStyle}
                                >
                                    Cancel
                                </Button>
                            ) : (
                                <>
                                    {(activeStep === 2) ? (
                                        <>
                                            {isVisibleModelStates?.isDisabledPurchaseLicenseButton === true ? (
                                                <LoadingButton
                                                    size="large"
                                                    disabled={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                    className={`${isVisibleModelStates?.isDisabledPurchaseLicenseButton ?
                                                        'theme-btn-generate-licenses-disabled-pur-lic' : "theme-btn-generate-licenses-pur-lic"}`}
                                                    loadingPosition="end"
                                                    variant="contained"
                                                >
                                                    Back
                                                </LoadingButton>
                                            ) : (
                                                <Button
                                                    disabled={activeStep === 0 || isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                    onClick={handleBack}
                                                    variant="contained"
                                                    type="button"
                                                    size="small"
                                                    sx={purchasebtnmodalStyle}
                                                >
                                                    Back
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <Button
                                            disabled={activeStep === 0 || isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                            onClick={handleBack}
                                            variant="contained"
                                            type="button"
                                            size="small"
                                            sx={{
                                                minWidth: "8rem!important",
                                                backgroundColor: isVisibleModelStates?.isDisabledPurchaseLicenseButton ? "#B6B6B6!important" : "#001D7D!important",
                                                color: "white!important",
                                                fontFamily: "Barlow!important",
                                                textTransform: "none!important",
                                                fontSize: "1.1rem",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Back
                                        </Button>
                                    )}
                                </>
                            )}
                            &nbsp;&nbsp;&nbsp;
                            <Box sx={{ flex: "1 1 auto" }} />
                            <>
                                {userAvailableCredit >= 0 ? (
                                    <>
                                        {(activeStep === 1) ? (
                                            <>
                                                {(selectedCard === "other" || !selectedCard) ? (
                                                    <LoadingButton
                                                        onClick={() => handleAddStripeCustomPayMethod()}
                                                        size="large"
                                                        loading={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                        endIcon={isVisibleModelStates?.isDisabledPurchaseLicenseButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                        disabled={(isVisibleModelStates?.isDisabledPurchaseLicenseButton || !selectedCard)}
                                                        className={`${(isVisibleModelStates?.isDisabledPurchaseLicenseButton || !selectedCard) ?
                                                            'add-payment-method-strip-btn-diable' : "add-payment-method-strip-btn-enable"}`}
                                                        loadingPosition="end"
                                                        variant="contained"
                                                    >
                                                        Next
                                                    </LoadingButton>
                                                ) : (
                                                    <>
                                                        {(activeStep === 2) ? (
                                                            <LoadingButton
                                                                onClick={() => handlePurchasePackage()}
                                                                size="large"
                                                                loading={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                                endIcon={isVisibleModelStates?.isDisabledPurchaseLicenseButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                                disabled={(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isTermsCondition === false ? true : false)}
                                                                className={`${(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isTermsCondition === false ? true : false) ?
                                                                    'add-payment-method-strip-btn-diable' : "add-payment-method-strip-btn-enable"}`}
                                                                loadingPosition="end"
                                                                variant="contained"
                                                            >
                                                                Confirm
                                                            </LoadingButton>
                                                        ) : (
                                                            <Button
                                                                disabled={userAvailableCredit >= 0 ? false : true}
                                                                variant="contained"
                                                                type="submit"
                                                                onClick={handleNext}
                                                                size="small"
                                                                sx={purchasebtnmodalStyle}
                                                            >
                                                                Next
                                                            </Button>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {(activeStep === 2) ? (
                                                    <LoadingButton
                                                        onClick={() => handlePurchasePackage()}
                                                        size="large"
                                                        loading={isVisibleModelStates?.isDisabledPurchaseLicenseButton}
                                                        endIcon={isVisibleModelStates?.isDisabledPurchaseLicenseButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                        disabled={(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isTermsCondition === false ? true : false)}
                                                        className={`${(isVisibleModelStates?.isDisabledPurchaseLicenseButton || isTermsCondition === false ? true : false) ?
                                                            'add-payment-method-strip-btn-diable' : "add-payment-method-strip-btn-enable"}`}
                                                        loadingPosition="end"
                                                        variant="contained"
                                                    >
                                                        Confirm
                                                    </LoadingButton>
                                                ) : (
                                                    <Button
                                                        disabled={userAvailableCredit >= 0 ? false : true}
                                                        variant="contained"
                                                        type="submit"
                                                        onClick={handleNext}
                                                        size="small"
                                                        sx={purchasebtnmodalStyle}
                                                    >
                                                        Next
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        size="small"
                                        disabled={userAvailableCredit >= 0 ? false : true}
                                        className="theme-btn-generate-licenses-disabled-purchase"
                                    >
                                        Next
                                    </Button>
                                )}
                            </>

                        </Box>
                    </DialogActions>
                </div>
            </Dialog >
            {/* {Change plan  package confirmation modal} */}
            <ConfirmationModal
                handleAPIRequest={handleChangePlan}
                isOpen={changePlan?.isChangePlanModalOpen}
                handleClose={handleCloseResubscribeModal}
                message={"Are you sure you want to switch to this package?"}
                title={"Change Package"}
                buttonText={"Confirm"}
                buttonText2={"Cancel"}
            />
            {/* {resubscribe  package confirmation modal} */}
            <ConfirmationModal
                handleAPIRequest={handleResubscribePackage}
                isOpen={resubscribeState?.isResubscribeModalOpen}
                handleClose={handleCloseResubscribeModal}
                message={"Are you sure you want to resubscribe this package?"}
                title={"Resubscribe Package"}
                buttonText={"Continue"}
                buttonText2={"Cancel"}
            />
            {/* {unsubscribe  package confirmation modal} */}
            <ConfirmationModal
                handleAPIRequest={handleUnsubscribePackage}
                isOpen={unsubscribeState?.isUnsubscribeModalOpen}
                handleClose={handleCloseUnsubscribeModal}
                message={"Are you sure you want to unsubscribe this package?"}
                title={"Unsubscribe Package"}
                buttonText={"Continue"}
                buttonText2={"Cancel"}
            />
            {/* {delete confirmation modal} */}
            <ConfirmationModal
                handleAPIRequest={handleDeleteStripePaymentMethod}
                isOpen={isDeleteModalShow}
                handleClose={handleCloseDeleteModal}
                message={"Are you sure you want to delete this payment method?"}
                title={"Delete"}
                buttonText={"Delete"}
                buttonText2={"Cancel"}
            />
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}
export default Packages;
